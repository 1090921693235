import {
  Modal,
  Label,
  Checkbox,
  Button,
  Spinner,
  TextInput,
} from "flowbite-react";
import React, { useState, useEffect } from "react";
import Select from "react-select";

const CreateStaffModal = ({
  isOpen,
  onClose,
  onSubmit,
  campusid,
  loading,
  clusterOptions,
}) => {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    campusId: campusid,
    clusterIds: [],
    isAdminStaff: false,
    mailAutomation: false,
    mailSettings: { frequency: "", studentFilter: "" },
  });

  useEffect(() => {
    setFormValues((prevValues) => ({ ...prevValues, campusId: campusid }));
  }, [campusid]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSelectChange = (selectedOptions) => {
    setFormValues({ ...formValues, clusterIds: selectedOptions || [] });
  };

  const handleCheckboxChange = () => {
    setFormValues({
      ...formValues,
      isAdminStaff: !formValues.isAdminStaff,
      clusterIds: [],
    });
  };

  const handleMailAutomationChange = () => {
    setFormValues({
      ...formValues,
      mailAutomation: !formValues.mailAutomation,
    });
  };

  const handleMailSettingsChange = (name, value) => {
    setFormValues({
      ...formValues,
      mailSettings: { ...formValues.mailSettings, [name]: value },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formValues);
  };

  const frequencyOptions = [
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
  ];

  const studentFilterOptions = [
    {
      value: "all",
      label: "All",
    },
    {
      value: "insights",
      label: "Insights",
    },
  ];

  return (
    <Modal show={isOpen} onClose={onClose}>
      <Modal.Header className="text-lg font-semibold">
        Create Staff
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit} className="space-y-5">
          <div>
            <Label htmlFor="name" value="Name" />
            <TextInput
              id="name"
              name="name"
              placeholder="Enter name"
              value={formValues.name}
              onChange={handleChange}
              required
              className="mt-1 block w-full rounded-md border-gray-300"
            />
          </div>

          {/* isAdminStaff Field */}
          <div className="flex items-center gap-2">
            <Checkbox
              id="isAdminStaff"
              name="isAdminStaff"
              checked={formValues.isAdminStaff}
              onChange={handleCheckboxChange}
            />
            <Label htmlFor="isAdminStaff">Is Admin Staff</Label>
          </div>

          {/* Email Field */}
          <div>
            <Label htmlFor="email" value="Email" />
            <TextInput
              id="email"
              type="email"
              name="email"
              placeholder="Enter email"
              value={formValues.email}
              onChange={handleChange}
              required
              className="mt-1 block w-full rounded-md border-gray-300"
            />
          </div>
          <div>
            <Label className="block text-sm font-medium text-gray-700">
              Mail Automation
            </Label>
            <div className="flex items-center space-x-4 mt-2">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="mailAutomation"
                  value={true}
                  checked={formValues.mailAutomation === true}
                  onChange={() =>
                    setFormValues((prev) => ({
                      ...prev,
                      mailAutomation: true,
                    }))
                  }
                  className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                />
                <span className="ml-2 text-sm font-medium text-gray-700">
                  On
                </span>
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="mailAutomation"
                  value={false}
                  checked={formValues.mailAutomation === false}
                  onChange={() =>
                    setFormValues((prev) => ({
                      ...prev,
                      mailAutomation: false,
                    }))
                  }
                  className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                />
                <span className="ml-2 text-sm font-medium text-gray-700">
                  Off
                </span>
              </label>
            </div>
          </div>

          {/* Mail Settings Fields */}
          {formValues.mailAutomation && (
            <div className="space-y-4 mt-4">
              {/* Frequency */}
              <div>
                <Label className="block text-sm font-medium text-gray-700">
                  Frequency
                </Label>
                <div className="flex items-center  space-x-5 mt-2">
                  {frequencyOptions.map((option) => (
                    <label key={option.value} className="flex items-center">
                      <input
                        type="radio"
                        name="frequency"
                        value={option.value}
                        checked={
                          formValues.mailSettings.frequency === option.value
                        }
                        onChange={() =>
                          handleMailSettingsChange("frequency", option.value)
                        }
                        className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                      />
                      <span className="ml-2 text-sm font-medium text-gray-700">
                        {option.label}
                      </span>
                    </label>
                  ))}
                </div>
              </div>

              <div>
                <Label className="block text-sm font-medium text-gray-700">
                  Mail Format
                </Label>
                <div className="flex items-center  space-x-5 mt-2">
                  {studentFilterOptions.map((option) => (
                    <label key={option.value} className="flex items-center">
                      <input
                        type="radio"
                        name="studentFilter"
                        value={option.value}
                        checked={
                          formValues.mailSettings.studentFilter === option.value
                        }
                        onChange={() =>
                          handleMailSettingsChange(
                            "studentFilter",
                            option.value
                          )
                        }
                        className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                      />
                      <span className="ml-2 text-sm font-medium text-gray-700">
                        {option.label}
                      </span>
                    </label>
                  ))}
                </div>
              </div>
            </div>
          )}

          {/* ClusterIds Field */}
          {!formValues.isAdminStaff && (
            <div>
              <Label htmlFor="clusters" value="Clusters" />
              <Select
                id="clusters"
                isMulti
                options={clusterOptions?.map((cluster) => ({
                  value: cluster.id,
                  label: cluster.name,
                }))}
                value={formValues.clusterIds}
                onChange={handleSelectChange}
                className="mt-1"
              />
            </div>
          )}

          <div className="flex justify-end gap-2 mt-4">
            <Button type="button" color="gray" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" color="success">
              {loading && <Spinner size="sm" className="mr-2" />}
              Create
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateStaffModal;
