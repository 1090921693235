import React, { useState } from "react";
import StudentLayout from "./Layout";
import ProgressOverview from "./Components/ProgressOverview";
import AcSubmissionChart from "./Components/AcSubmissionChart";
import {
  useGetCampusQuery,
  useGetDashboardCourseDetailsQuery,
  useGetLeetcodeDataQuery,
  useGetStudentByEmailIdQuery,
  useGetStudentQuery,
} from "../../graphql/generated.tsx";
import Leaderboard from "./Components/Leaderboard";
import SubmissionGraph from "./Components/SubmissionGraph";
import { Button, Card, Spinner } from "flowbite-react";
import { TfiArrowTopRight } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";
import DailyActivityGraph from "./Components/DailyActivityGraph.jsx";
import StudentCourseProgression from "./Components/StudentCourseProgression.jsx";
import Overlay from "./Components/Overlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import AdminLayout from "../Admin/Layout/index.jsx";
import { IoMdArrowRoundBack } from "react-icons/io";
import { calculateCourseStats } from "./Utils/calculateCourseStats.js";

const StudentDashboard = ({ isAdmin }) => {
  const { studentid } = useParams();
  const navigate = useNavigate();
  const email = JSON.parse(localStorage.getItem("user"))?.email;
  const [paidDashboard, setpaidDashboard] = useState(true);
  const { data: studentData, loading: studentLoading } =
    useGetStudentByEmailIdQuery({
      variables: { email },
    });

  const { data: studentdata, loading: studentloading } = useGetStudentQuery({
    variables: { id: studentid },
  });

  const student = isAdmin
    ? studentdata?.getStudent
    : studentData?.getStudentByEmailId;

  const purchasesCourses = student?.purchasedCourses;

  const { data: campusDetails, loading: campusLoading } = useGetCampusQuery({
    variables: { id: student?.campusId },
    skip: !student,
  });

  const campus = campusDetails?.campus;
  const leetcodeUname = student?.leetCodeUname;

  const { data: leetCodeData, loading: leetcodeLoading } =
    useGetLeetcodeDataQuery({
      variables: { username: leetcodeUname },
      skip: !student,
    });

  const { data: Courses, loading: coursesLoading } =
    useGetDashboardCourseDetailsQuery({
      variables: { student_id: student?.id, is_paid: !paidDashboard },
      skip: !student,
    });

  const {
    completed: courseCompleted,
    inProgress: courseInprogress,
    enrolled: courseEnrolled,
  } = calculateCourseStats(Courses?.getDashboardCourseDetails?.courseDetails);

  const acSubmissionNum =
    leetCodeData?.leetcodeData?.matchedUser?.submitStats?.acSubmissionNum;

  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  const isLoading =
    studentLoading || campusLoading || leetcodeLoading || coursesLoading;

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner size="xl" aria-label="Loading dashboard..." />
      </div>
    );
  }

  const Layout = isAdmin ? AdminLayout : StudentLayout;

  return (
    <Layout>
      <div className="m-5 md:m-10 max-md:mx-5 max-md:ml-10 relative">
        {!isAdmin && purchasesCourses?.length > 0 && (
          <div className="flex justify-end w-full">
            <div className="flex space-x-4">
              <div className="flex bg-white rounded-md border border-primary">
                <button
                  className={`px-4 py-1.5 rounded-md ${
                    paidDashboard ? "bg-primary text-white" : ""
                  }`}
                  onClick={() => setpaidDashboard(true)}
                >
                  Assigned Course
                </button>
                <button
                  className={`px-4 py-1.5 rounded-md ${
                    !paidDashboard ? "bg-primary text-white" : ""
                  }`}
                  onClick={() => setpaidDashboard(false)}
                >
                  Paid Courses
                </button>
              </div>
            </div>
          </div>
        )}

        {isAdmin && (
          <div className="flex justify-between items-center mb-8 max-md:gap-2">
            <div className="flex items-center">
              <button
                onClick={() => window.history.back()}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                <IoMdArrowRoundBack className="h-6 w-6" />
              </button>
              <div className="text-2xl font-bold ml-2 max-md:text-lg">
                {student?.name || student?.email}
              </div>
            </div>
          </div>
        )}
        {!isAdmin && (
          <div className="flex flex-col md:flex-row justify-between items-start gap-10">
            <div className="w-full md:w-[25%] flex flex-col justify-between h-full p-5 gap-3 relative">
              <h1 className="text-2xl md:text-3xl font-semibold">
                Welcome back, {student?.name}!
              </h1>
              <p className="text-sm md:text-md text-black mt-1">
                You can find the progress overview of your courses.
              </p>
              <Button
                className="p-0 bg-primary mt-5 w-full md:w-[145px] flex justify-between items-center max-md:w-[145px]"
                onClick={() =>
                  navigate(`/student/courses/${student?.clusterIds?.[0]}`)
                }
              >
                View Courses <TfiArrowTopRight className="mt-1 ml-1" />
              </Button>
            </div>
            <div className="w-full relative p-5">
              <ProgressOverview Courses={Courses} />
            </div>
          </div>
        )}

        {/* New Statistics Section */}
        <div className="mt-8 flex flex-col md:flex-row justify-between gap-5">
          <div className="w-full md:w-[50%] relative">
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
              <Card>
                <h2 className="text-2xl md:text-3xl font-bold text-center">
                  {courseEnrolled}
                </h2>
                <p className="text-center">Courses Enrolled</p>
              </Card>
              <Card>
                <h2 className="text-2xl md:text-3xl font-bold text-center">
                  {courseInprogress}
                </h2>
                <p className="text-center">Course Inprogress</p>
              </Card>
              <Card>
                <h2 className="text-2xl md:text-3xl font-bold text-center">
                  {courseCompleted}
                </h2>
                <p className="text-center">Courses Completed</p>
              </Card>

              <Card className="shadow-lg">
                <div className="flex flex-col items-center justify-center px-2">
                  <div className="relative">
                    <FontAwesomeIcon
                      icon={faTrophy}
                      className="text-secondary text-4xl md:text-5xl mb-2"
                    />
                    <span className="absolute -top-2 -right-2 bg-primary text-white rounded-full px-2 py-1 text-xs font-bold">
                      {Courses?.getDashboardCourseDetails?.rewardPoints || 0}
                    </span>
                  </div>
                  <p className="text-center">Reward Points</p>
                </div>
              </Card>
            </div>
            <div className="mt-6 relative h-full max-md:h-[80%] overflow-auto">
              <SubmissionGraph
                studentId={student?.id}
                campusName={campus?.name}
                month={currentMonth}
                year={currentYear}
                paidDashboard={paidDashboard}
              />
            </div>
          </div>
          <div className="w-full md:w-[50%] h-full relative">
            <DailyActivityGraph
              studentId={student?.id}
              campusName={campus?.name}
              month={currentMonth}
              year={currentYear}
              isAdmin={isAdmin}
              paidDashboard={paidDashboard}
            />
          </div>
        </div>

        <div className="mt-8 flex flex-col md:flex-row justify-between gap-5">
          <div className="w-full md:w-[50%] h-full relative">
            <h1 className="text-xl md:text-2xl font-bold my-4">
              LeetCode Progress
            </h1>
            <div className="relative">
              <AcSubmissionChart
                isLoading={leetcodeLoading}
                acSubmissionNum={acSubmissionNum}
              />
              {!acSubmissionNum?.[0]?.count && !leetcodeLoading && (
                <Overlay message="Invalid LeetCode username" />
              )}
            </div>
          </div>
          <div className="w-full md:w-[50%] relative">
            <h1 className="text-xl md:text-2xl font-bold my-4">
              Overall Course Progress
            </h1>
            <StudentCourseProgression
              studentData={student}
              isAdmin={isAdmin}
              paidDashboard={paidDashboard}
            />
            {!student && (
              <Overlay message="Course progress data not available" />
            )}
          </div>
        </div>
        {!isAdmin &&
          paidDashboard &&
          student?.clusterIds.map((clusterid) => (
            <div className="relative" key={clusterid}>
              <Leaderboard
                key={clusterid}
                clusterId={clusterid}
                campusName={campus?.name}
                studentId={student.id}
              />
              {!student && <Overlay message="Leaderboard data not available" />}
            </div>
          ))}
        {!isAdmin &&
          !paidDashboard &&
          Array.from(
            new Set(
              student?.purchasedCourses.map((course) => course.cluster_id)
            )
          ).map((clusterId, index) => (
            <div className="relative" key={index}>
              <Leaderboard
                key={index}
                clusterId={clusterId}
                campusName={"Better Tomorrow"}
                studentId={student.id}
              />
              {!student && <Overlay message="Leaderboard data not available" />}
            </div>
          ))}
      </div>
    </Layout>
  );
};

export default StudentDashboard;
