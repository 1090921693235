import React, { useEffect, useState } from "react";
import AdminLayout from "../../Layout/index.jsx";
import {
  useCreateStaffMutation,
  useGetStaffsByCampusQuery,
  useSendInviteEmailToStaffMutation,
  useDeleteStaffMutation,
  useGetCampusQuery,
  useGetClustersByCampusQuery,
  useEditStaffMutation,
  useUpdateStaffMutation, // Import the edit staff mutation
} from "../../../../graphql/generated.tsx";
import { useParams } from "react-router-dom";
import {
  IoMdAdd,
  IoMdArrowRoundBack,
  IoMdSend,
  IoMdTrash,
  IoMdCreate, // Import edit icon
} from "react-icons/io";
import { MdCheckCircle, MdVerified } from "react-icons/md";
import { Button, Table } from "flowbite-react";
import { toast } from "react-toastify";
import CreateStaffModal from "../../Components/CreateStaffModal.jsx";
import EditStaffModal from "../../Components/EditStaffModal.jsx"; // Import the edit staff modal

const CampusDetails = () => {
  const { id } = useParams();
  const {
    loading: staffLoading,
    error: staffError,
    data: staffData,
    refetch,
  } = useGetStaffsByCampusQuery({
    variables: { campusId: id },
  });
  const {
    loading: campusLoading,
    error: campusError,
    data: campusData,
  } = useGetCampusQuery({ variables: { id } });

  const [staff, setStaff] = useState([]);
  const [createStaff, { loading: createLoading }] = useCreateStaffMutation();
  const [editStaff, { loading: editLoading }] = useUpdateStaffMutation();
  const [inviteStaff] = useSendInviteEmailToStaffMutation();
  const [deleteStaff] = useDeleteStaffMutation();
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [editingStaff, setEditingStaff] = useState(null);

  const { data: clustersData } = useGetClustersByCampusQuery({
    variables: { campusName: campusData?.campus?.name },
    skip: !campusData?.campus?.name,
  });

  const clusterOptions = clustersData?.getClustersByCampus;

  const clusterMap =
    clusterOptions?.reduce((acc, cluster) => {
      acc[cluster.id] = cluster.name;
      return acc;
    }, {}) || {};

  useEffect(() => {
    if (staffData) {
      setStaff(staffData.getStaffsByCampus);
    }
  }, [staffData]);

  const handleInviteStaff = async (emails) => {
    try {
      await inviteStaff({ variables: { emails } });
      setStaff((prev) =>
        prev.map((s) =>
          emails.includes(s.email) ? { ...s, isInviteSend: true } : s
        )
      );
      toast.success("Invite sent successfully");
    } catch (err) {
      console.error("Error inviting staff:", err);
      toast.error("Failed to send invite");
    }
  };

  const handleCreateStaff = async (formValues) => {
    const { name, email, clusterIds, isAdminStaff } = formValues;
    try {
      const { data: newStaffData } = await createStaff({
        variables: {
          createStaffInput: {
            name,
            email,
            clusterIds: isAdminStaff
              ? null
              : clusterIds?.map((val) => val.value),
            campusId: id,
            isAdminStaff,
          },
        },
      });

      if (newStaffData.createStaff.message.includes("already exists")) {
        toast.error("Email already exists");
      } else {
        setStaff((prev) => [...prev, newStaffData.createStaff.staff]);
        setOpenModal(false);
        toast.success("Staff created successfully");
      }
    } catch (err) {
      console.error("Error creating staff:", err);
      toast.error("Failed to create staff");
    }
  };

  const handleEditStaff = async (formValues) => {
    const {
      name,
      email,
      clusterIds,
      isAdminStaff,
      mailAutomation,
      mailSettings,
    } = formValues;
    console.log({email})
    try {
      await editStaff({
        variables: {
          updateStaffInput: {
            id: editingStaff.id,
            name,
            email,
            clusterIds: isAdminStaff ? null : clusterIds?.map((val) => val),
            isAdminStaff,
            mailAutomation: mailAutomation,
            mailSettings: mailSettings,
          },
        },
      });
      refetch();
      setOpenEditModal(false);
      toast.success("Staff updated successfully");
    } catch (err) {
      console.error("Error editing staff:", err);
      toast.error("Failed to edit staff");
    }
  };
  const handleDeleteStaff = async (staffId) => {
    try {
      await deleteStaff({ variables: { id: staffId } });
      setStaff((prev) => prev.filter((s) => s.id !== staffId));
      toast.success("Staff deleted successfully");
    } catch (err) {
      console.error("Error deleting staff:", err);
      toast.error("Failed to delete staff");
    }
  };

  const openEditModalHandler = (staffMember) => {
    setEditingStaff(staffMember);
    setOpenEditModal(true);
  };

  if (staffLoading || campusLoading) {
    return (
      <AdminLayout>
        <div className="flex items-center justify-center h-screen">
          <p>Loading...</p>
        </div>
      </AdminLayout>
    );
  }

  if (staffError || campusError) {
    return (
      <AdminLayout>
        <p>Error: {staffError?.message || campusError?.message}</p>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <div className="p-10">
        <div className="flex justify-between items-center mb-8 max-md:gap-2">
          <div className="flex items-center space-x-3">
            <IoMdArrowRoundBack size={25} className="cursor-pointer" />
            <h1 className="text-3xl font-bold">{campusData.campus.name}</h1>
          </div>
          <Button onClick={() => setOpenModal(true)} className="p-0 bg-primary">
            <IoMdAdd size={20} />
            Create Staff
          </Button>
        </div>
        <Table>
          <Table.Head>
            <Table.HeadCell>Staff Name</Table.HeadCell>
            <Table.HeadCell>Email</Table.HeadCell>
            <Table.HeadCell className="text-center">Clusters</Table.HeadCell>
            <Table.HeadCell>Actions</Table.HeadCell>
          </Table.Head>
          <Table.Body>
            {staff.map((staffMember) => (
              <Table.Row
                key={staffMember.id}
                onMouseEnter={() => setHoveredRow(staffMember.id)}
                onMouseLeave={() => setHoveredRow(null)}
                className={"bg-white"}
              >
                <Table.Cell className="flex gap-2 items-center">
                  {" "}
                  <span>{staffMember.name}</span>
                  {staffMember.isAdminStaff && (
                    <MdVerified
                      size={15}
                      className="text-yellow-500"
                      title="Admin"
                    />
                  )}
                </Table.Cell>
                <Table.Cell>{staffMember.email}</Table.Cell>
                <Table.Cell className="text-center">
                  {staffMember?.clusterIds?.length === 0 ||
                  !staffMember?.clusterIds
                    ? "-"
                    : staffMember?.clusterIds
                        ?.map((clusterId) => clusterMap[clusterId])
                        .join(", ")}
                </Table.Cell>
                <Table.Cell>
                  <div className="flex items-center space-x-2">
                    <Button
                      onClick={() => openEditModalHandler(staffMember)}
                      className="bg-primary"
                      size="xs"
                    >
                      <IoMdCreate size={15} />
                    </Button>
                    <Button
                      onClick={() => handleDeleteStaff(staffMember.id)}
                      color="failure"
                      size="xs"
                    >
                      <IoMdTrash size={15} />
                    </Button>
                    {staffMember.isInviteSend && (
                      <span className="text-green-500">
                        <MdCheckCircle />
                      </span>
                    )}
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <CreateStaffModal
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          onSubmit={handleCreateStaff}
          loading={createLoading}
          clusterOptions={clusterOptions}
        />
        <EditStaffModal
          isOpen={openEditModal}
          onClose={() => setOpenEditModal(false)}
          onSubmit={handleEditStaff}
          staffDetails={editingStaff}
          loading={editLoading}
          clusterOptions={clusterOptions}
        />
      </div>
    </AdminLayout>
  );
};

export default CampusDetails;
