import React from "react";
import NavBar from "../../Common/NavBar";

const TermsAndConditions = () => {
  return (
    <>
      <NavBar />
      <div className="p-10 bg-gray-100 text-gray-800 mt-10">
        <div className="bg-white p-12 rounded-lg max-w-6xl mx-auto ">
          <h1 className="text-4xl font-extrabold text-center text-gray-900 mb-3">
            Terms & Conditions
          </h1>
          <p className="text-sm text-gray-500 text-center italic mb-10">
            Last updated on 13-12-2024 19:27:44
          </p>

          <div className="space-y-6">
            <p className="leading-relaxed">
              These Terms and Conditions, along with the privacy policy or other
              terms (“Terms”) constitute a binding agreement by and between{" "}
              <span className="font-semibold">
                BETTER TOMORROW TRAINING INSTITUTE LLP
              </span>
              (“Website Owner” or “we” or “us” or “our”) and you (“you” or
              “your”) and relate to your use of our website, goods (as
              applicable), or services (as applicable) (collectively,
              “Services”).
            </p>

            <p className="leading-relaxed">
              By using our website and availing of the Services, you agree that
              you have read and accepted these Terms (including the Privacy
              Policy). We reserve the right to modify these Terms at any time
              and without assigning any reason. It is your responsibility to
              periodically review these Terms to stay informed of updates.
            </p>

            <p className="font-semibold text-gray-700">
              The use of this website or availing of our Services is subject to
              the following terms of use:
            </p>

            <ul className="list-none space-y-5 p-3">
              <li className="leading-relaxed before:content-['\2756'] before:pr-2 before:text-black">
                To access and use the Services, you agree to provide true,
                accurate, and complete information to us during and after
                registration, and you shall be responsible for all acts done
                through the use of your registered account.
              </li>
              <li className="leading-relaxed before:content-['\2756'] before:pr-2 before:text-black">
                Neither we nor any third parties provide any warranty or
                guarantee as to the accuracy, timeliness, performance,
                completeness, or suitability of the information and materials
                offered on this website or through the Services, for any
                specific purpose. You acknowledge that such information and
                materials may contain inaccuracies or errors, and we expressly
                exclude liability for any such inaccuracies or errors to the
                fullest extent permitted by law.
              </li>
              <li className="leading-relaxed before:content-['\2756'] before:pr-2 before:text-black">
                Your use of our Services and the website is solely at your own
                risk and discretion. You are required to independently assess
                and ensure that the Services meet your requirements.
              </li>
              <li className="leading-relaxed before:content-['\2756'] before:pr-2 before:text-black">
                The contents of the Website and the Services are proprietary to
                Us, and you will not have any authority to claim any
                intellectual property rights, title, or interest in its
                contents.
              </li>
              <li className="leading-relaxed before:content-['\2756'] before:pr-2 before:text-black">
                You acknowledge that unauthorized use of the Website or the
                Services may lead to action against you as per these Terms or
                applicable laws.
              </li>
              <li className="leading-relaxed before:content-['\2756'] before:pr-2 before:text-black">
                You agree to pay us the charges associated with availing of the
                Services.
              </li>
              <li className="leading-relaxed before:content-['\2756'] before:pr-2 before:text-black">
                You agree not to use the website and/or Services for any purpose
                that is unlawful, illegal, or forbidden by these Terms, or
                Indian or local laws that might apply to you.
              </li>
              <li className="leading-relaxed before:content-['\2756'] before:pr-2 before:text-black">
                You agree and acknowledge that the website and the Services may
                contain links to other third-party websites. On accessing these
                links, you will be governed by the terms of use, privacy policy,
                and such other policies of such third-party websites.
              </li>
              <li className="leading-relaxed before:content-['\2756'] before:pr-2 before:text-black">
                You understand that upon initiating a transaction for availing
                of the Services, you are entering into a legally binding and
                enforceable contract with us for the Services.
              </li>
              <li className="leading-relaxed before:content-['\2756'] before:pr-2 before:text-black">
                Notwithstanding anything contained in these Terms, the parties
                shall not be liable for any failure to perform an obligation
                under these Terms if performance is prevented or delayed by a
                force majeure event.
              </li>
              {/* Refund Policy */}
              <li className="leading-relaxed before:content-['\2756'] before:pr-2 before:text-black">
                No refund will be issued after purchase.
              </li>
            </ul>
          </div>
          <p className="font-semibold text-gray-700 my-4">Refund Policy</p>
          <div className="space-y-4">
            <p className="leading-relaxed">
              At{" "}
              <span className="font-semibold">
                BETTER TOMORROW TRAINING INSTITUTE LLP
              </span>
              , we strive to deliver high-quality educational content and a
              seamless learning experience. Please carefully review our refund
              policy before making a purchase.
            </p>

            <h2 className="font-bold text-md">1. No Refunds After Purchase</h2>
            <p className="leading-relaxed">
              Once a course has been purchased, no refund will be issued under
              any circumstances. This includes but is not limited to:
            </p>
            <ul className="list-disc pl-8 space-y-2">
              <li>
                <span className="font-semibold">Change of Mind:</span> If you
                change your mind after completing the purchase.
              </li>
              <li>
                <span className="font-semibold">Lack of Usage:</span> If you do
                not use or access the course.
              </li>
              <li>
                <span className="font-semibold">Dissatisfaction:</span> If the
                course content does not meet your expectations.
              </li>
            </ul>
            <p className="leading-relaxed">
              We strongly encourage you to review the course description,
              syllabus, and any available previews before making a purchase.
            </p>

            <h2 className="font-bold text-md">
              2. Course Access and Digital Nature
            </h2>
            <p className="leading-relaxed">
              Once a course is purchased, you gain instant access to the digital
              content, which is non-returnable. As such, refunds are not
              feasible due to the nature of the product.
            </p>

            <h2 className="font-bold text-md">3. Technical Issues</h2>
            <p className="leading-relaxed">
              In case of technical difficulties accessing the course, we will
              provide full support to resolve the issue. However, refunds will
              not be granted for technical challenges unless they are verified
              to be unresolvable by our support team.
            </p>

            <h2 className="font-bold text-md">4. Exceptional Circumstances</h2>
            <p className="leading-relaxed">
              Refunds may be considered under rare circumstances, such as:
            </p>
            <ul className="list-disc pl-8 space-y-2">
              <li>Duplicate transactions.</li>
              <li>Payment errors directly caused by the platform.</li>
            </ul>
            <p className="leading-relaxed">
              These situations will require submission of proof and review by
              our support team.
            </p>

            <h2 className="font-bold text-md">Contact Support</h2>
            <p className="leading-relaxed">
              For any questions or concerns, please contact our support team at{" "}
              <a
                href="mailto:support@thebettertommorrow.in"
                className="text-blue-600"
              >
                support@thebettertommorrow.in
              </a>
              . We are here to help resolve any issues you might face during
              your learning journey.
            </p>
            <p className="leading-relaxed">
              By purchasing a course on{" "}
              <span className="font-semibold">
                BETTER TOMORROW TRAINING INSTITUTE LLP
              </span>
              , you acknowledge and agree to the terms of this refund policy.
              Thank you for your understanding!
            </p>
          </div>
          <p className="leading-relaxed">
            These Terms and any dispute or claim relating to it, or its
            enforceability, shall be governed by and construed in accordance
            with the laws of India.
          </p>
          <p className="leading-relaxed">
            All disputes arising out of or in connection with these Terms shall
            be subject to the exclusive jurisdiction of the courts in TIRUPPUR,
            Tamil Nadu.
          </p>
          <p className="leading-relaxed">
            All concerns or communications relating to these Terms must be
            communicated to us using the contact information provided on this
            website.
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
