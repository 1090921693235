import React from "react";
import { Route } from "react-router-dom";
import StudentData from "../Modules/Admin/Screens/Student_Creation/Students";
import StudentInfo from "../Modules/Admin/Screens/Student_Creation/StudentInfo";
import AllStudentsInCampus from "../Modules/Admin/Screens/Student_Creation/AllStudentsInCampus";
import StaffMyProfile from "../Modules/Admin/Screens/Staffs/StaffMyProfile";
import Reports from "../Modules/Admin/Screens/Reports/Reports";
import ReportsClusterListing from "../Modules/Admin/Screens/Reports/ReportsClusterListing";
import ClusterReport from "../Modules/Admin/Screens/Reports/ClusterReport";
import HomePage from "../Modules/HomePage";
import ReportType from "../Modules/Admin/Screens/Reports/ReportType";
import AssignmentListing from "../Modules/Admin/Screens/Reports/AssignmentListing";
import AssignmentReport from "../Modules/Admin/Screens/Reports/AssignmentReport";
import AssignmentCampusListing from "../Modules/Admin/Screens/Reports/AssignmentCampusListing";
import StudentDashboard from "../Modules/Student";
import { elements } from "chart.js";
import AssignmentNotAttendee from "../Modules/Admin/Screens/Reports/AssignmentNotAttendee";

const adminRoutes = [
  <Route
    key="students-data"
    path="/admin/students"
    element={<StudentData />}
  />,
  <Route
    key="student-details"
    path="/admin/student-details/:id"
    element={<StudentInfo />}
  />,
  <Route
    key="all-students-in-campus"
    path="/admin/student-details"
    element={<AllStudentsInCampus />}
  />,
  <Route
    key="staff-my-profile"
    path="/staff/my-profile"
    element={<StaffMyProfile />}
  />,
  <Route
    key="reports"
    path="admin/reports"
    element={<ReportType />}
  />,
  <Route
    key="course-reports"
    path="admin/reports/course"
    element={<Reports />}
  />,
  <Route
    key="student-dashboard"
    path="/admin/student/:studentid"
    element={<StudentDashboard isAdmin={true} />}
  />,
  <Route
    key="assignment-listing"
    path="admin/reports/assignments/:campusName"
    element={<AssignmentListing />}
  />,
  <Route
    key="assignment-report"
    path="admin/reports/assignments/:campusName/:assignment_id"
    element={<AssignmentReport />}
  />,
  <Route
    key="assignment-not-attendee"
    path="/admin/reports/assignments/:campusName/:assignment_id/notAttendee"
    element={<AssignmentNotAttendee />}
  />,
  <Route
    key="reports-cluster-listing"
    path="admin/reports/course/:campusName"
    element={<ReportsClusterListing />}
  />,
  <Route
    key="cluster-report"
    path="admin/reports/course/:campusName/:clusterId"
    element={<ClusterReport />}
  />,
];

export default adminRoutes;
