import React, { useEffect, useState } from "react";
import { Badge, Button, Tabs } from "flowbite-react";
import SampleTestCaseTable from "./SampleTestCaseTable";
import { HiClipboardList, HiUserCircle } from "react-icons/hi";
import { useGetLastFiveSubmissionsQuery } from "../../../graphql/generated.tsx";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const CompilerQuestionCard = ({
  question,
  loading,
  onApply,
  hideSubmission,
  extractFunctionCode,
  showFunctionOnly,
  targetFunctionName,
  submitInput,
}) => {
  const [submissions, setSubmissions] = useState([]);
  const [expandedSubmissions, setExpandedSubmissions] = useState({});

  const { data } = useGetLastFiveSubmissionsQuery({
    variables: { submitInput },
    skip: !submitInput,
  });

  useEffect(() => {
    if (data?.getLastFiveSubmissions) {
      const sortedSubmissions = [...data.getLastFiveSubmissions.submissions].sort((a, b) => new Date(b.submittedAt) - new Date(a.submittedAt));
      setSubmissions(sortedSubmissions);
    }
  }, [data, question]);
  const onCopyHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const toggleSubmission = (index) => {
    setExpandedSubmissions((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  if (loading) {
    return (
      <div className="shadow-md bg-white p-8 rounded-md items-center">
        Loading...
      </div>
    );
  }

  const isCompleted = data?.getLastFiveSubmissions?.is_completed;

   const highlightCodeTags = (html) => {
    return html && html.replace(/<code>(.*?)<\/code>/g, (match, content) => {
      return `<code class="bg-gray-200 text-sm rounded-sm font-mono p-1 rounded not-prose">${content}</code>`;
    });
  };

  return (
    <Tabs aria-label="Tabs with underline" variant="fullWidth">
      <Tabs.Item active title="Question" icon={HiUserCircle}>
        <div
          className="relative shadow-md bg-white p-8 rounded-md"
          onCopy={onCopyHandler}
        >
          <h3 className="text-2xl font-semibold">{question?.title}</h3>
          <div className="py-2 w-full max-w-none prose custom-bullet-list">
             <div
               className="box-container"
               dangerouslySetInnerHTML={{
                 __html: highlightCodeTags(question?.text),
               }}
             />
           </div>

          {/* {question?.question_sub_text && (
            <p className="mt-4 text-gray-600 dark:text-gray-400 w-full">
              <pre className="whitespace-pre-wrap break-words max-w-full font-sans text-black">
                {question?.question_sub_text}
              </pre>
            </p>
          )} */}

          {isCompleted && (
            <Badge
              color="green"
              className="absolute top-8 right-8 max-md:top-0"
            >
              Completed
            </Badge>
          )}

          <h4 className="text-xl font-semibold mt-4">Sample Test Case:</h4>
          <div className="w-1/2 max-md:w-full">
            <SampleTestCaseTable question={question} />
          </div>

          {question?.hints?.length > 0 && (
            <div className="mt-4">
              <span className="font-semibold text-gray-600 dark:text-gray-400">
                Hints:
              </span>
              <ul>
                {question.hints.map(
                  (hint, index) =>
                    hint && (
                      <li key={index}>
                        {index + 1}. {hint}
                      </li>
                    )
                )}
              </ul>
            </div>
          )}
        </div>
      </Tabs.Item>

      {!hideSubmission && (
        <Tabs.Item title="Submissions" icon={HiClipboardList}>
          <div className="relative shadow-md bg-white p-8 rounded-md">
            <h4 className="text-xl font-semibold mt-4">Last 5 Submissions:</h4>
            {submissions.length > 0 ? (
              submissions.map((submission, index) => {
                const langkey = submission?.language?.toLowerCase() || "c";
                return (
                  <div
                    key={submission.id}
                    className="p-4 border border-gray-300 rounded-md my-3 cursor-pointer hover:shadow-lg transition-shadow"
                    onClick={() => toggleSubmission(index)}
                  >
                    <div className="flex justify-between items-center">
                      <div>
                        <p className="font-semibold">Submission {index + 1}</p>
                        <p className="text-gray-600">
                          Score: {submission.score}
                        </p>
                        <div className="flex items-center space-x-2">
                          <span className="text-gray-600">Language:</span>
                          <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full">
                            {submission.language || "C"}
                          </span>
                        </div>
                        <p className="text-gray-600">
                          Submitted At:{" "}
                          {new Date(submission.submittedAt).toLocaleString()}
                        </p>
                      </div>
                      <div className="flex items-center space-x-4">
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            onApply(submission.code, submission.language);
                          }}
                          className="bg-primary text-white rounded-md hover:bg-primary-dark"
                        >
                          Apply
                        </Button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleSubmission(index);
                          }}
                          className="text-gray-500 hover:text-gray-700"
                        >
                          {expandedSubmissions[index] ? (
                            <FaChevronUp />
                          ) : (
                            <FaChevronDown />
                          )}
                        </button>
                      </div>
                    </div>

                    {expandedSubmissions[index] && (
                      <pre className="mt-2 bg-gray-100 p-4 rounded max-w-full overflow-x-auto">
                        {showFunctionOnly
                          ? extractFunctionCode(
                              submission?.code,
                              langkey,
                              targetFunctionName
                            )
                          : submission?.code}
                      </pre>
                    )}
                  </div>
                );
              })
            ) : (
              <p className="text-gray-600">No submissions found.</p>
            )}
          </div>
        </Tabs.Item>
      )}
    </Tabs>
  );
};

export default CompilerQuestionCard;
