import React, { useState } from "react";
import AdminLayout from "../../Layout";
import {
  useGetAssignmentQuery,
  useGetCampusByNameQuery,
  useGetClustersByCampusQuery,
  useStudentsNotAttendedAssignmentQuery,
} from "../../../../graphql/generated.tsx";
import { useParams } from "react-router-dom";
import fileDownload from "js-file-download";
import { Button, Table, Select, Spinner } from "flowbite-react";
import { SkeletonRow } from "../../../Student/Components/LeaderBoardSkeleton.jsx";
import { IoMdArrowRoundBack } from "react-icons/io";
import { AiOutlineDownload } from "react-icons/ai";

const AssignmentNotAttendee = () => {
  const { campusName, assignment_id } = useParams();
  const [selectedCluster, setSelectedCluster] = useState();

  const { data: campus, loading: campusLoading } = useGetCampusByNameQuery({
    variables: { name: campusName },
  });
  const { data: Assignment } = useGetAssignmentQuery({
    variables: { id: assignment_id },
  });

  const { data, loading, refetch } = useStudentsNotAttendedAssignmentQuery({
    variables: {
      campus_id: campus?.getCampusByName.id,
      assignmentId: assignment_id,
      cluster_id: selectedCluster,
    },
    skip: !campus,
  });

  const { data: clusters, loading: clusterLoading } =
    useGetClustersByCampusQuery({
      variables: { campusName: campusName },
      skip: campusLoading,
    });

  const students = data?.studentsNotAttendedAssignment || [];

  const handleClusterChange = (e) => {
    const clusterId = e.target.value;
    setSelectedCluster(clusterId);
    refetch({ cluster_id: clusterId });
  };

  const handleDownloadCSV = () => {
    const csvContent = [
      ["Name", "Email", "Roll No", "Cluster Name"],
      ...students.map((student) => [
        student.student_name,
        student.student_email,
        student.student_rollno,
        student.cluster_names,
      ]),
    ]
      .map((row) => row.join(","))
      .join("\n");

    fileDownload(
      csvContent,
      `students-not-attended ${Assignment?.getAssignment.name}.csv`
    );
  };

  return (
    <AdminLayout>
      
      <div className="container mx-auto p-8">
        {/* Table */}
        <div className="overflow-x-auto shadow-lg rounded-lg bg-white">
          <div className="flex flex-col sm:flex-row justify-between w-full mb-10 relative pb-5 border-b p-6">
            <div className="flex items-center mb-5 sm:mb-0">
              <button
                onClick={() => window.history.back()}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                <IoMdArrowRoundBack className="h-6 w-6" />
              </button>
              <h1 className="text-2xl font-bold ml-2 max-md:text-lg">
                {Assignment?.getAssignment.name}
              </h1>
            </div>
            <p>
              <span className="font-bold">Non Participants Count: </span>
              {students.length}
            </p>
          </div>

          <div className="flex justify-between w-full items-center px-6">
            <div className="mb-6">
              <label
                htmlFor="cluster-filter"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Filter by Batch
              </label>
              <Select
                id="cluster-filter"
                value={selectedCluster || ""}
                onChange={handleClusterChange}
                className="w-full md:w-72"
                disabled={clusterLoading || campusLoading}
              >
                <option value="">Whole Campus</option>
                {clusters?.getClustersByCampus.map((cluster) => (
                  <option key={cluster.id} value={cluster.id}>
                    {cluster.name}
                  </option>
                ))}
              </Select>
            </div>
            <div className="mb-4">
              <Button
                onClick={handleDownloadCSV}
                className="bg-primary focus:ring-blue-500 w-full md:w-auto"
              >
                <AiOutlineDownload className="mr-2 h-5 w-4" />
                Download
              </Button>
            </div>
          
          </div>

          <div className="mb-4">
            <Button
              onClick={handleDownloadCSV}
              className="bg-primary focus:ring-blue-500 w-full md:w-auto"
            >
              Download Report
            </Button>
          </div>
        </div>

        <div className="mb-6">
          <label
            htmlFor="cluster-filter"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Filter by Cluster
          </label>
          <Select
            id="cluster-filter"
            value={selectedCluster || ""}
            onChange={handleClusterChange}
            className="w-full md:w-72"
            disabled={clusterLoading || campusLoading}
          >
            <option value="">Whole Campus</option>
            {clusters?.getClustersByCampus.map((cluster) => (
              <option key={cluster.id} value={cluster.id}>
                {cluster.name}
              </option>
            ))}
          </Select>
        </div>

        {(campusLoading || clusterLoading) && (
          <div className="flex justify-center items-center mb-6">
            <Spinner aria-label="Loading" />
          </div>
        )}

        {/* Table */}
        <div className="overflow-x-auto shadow-lg rounded-lg">
          <Table striped hoverable className="min-w-full bg-white border-b">
            <Table.Head>
              <Table.HeadCell>Name</Table.HeadCell>
              <Table.HeadCell>Email</Table.HeadCell>
              <Table.HeadCell>Roll No</Table.HeadCell>
              <Table.HeadCell>Cluster Names</Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {loading ? (
                <>
                  <SkeletonRow col={4} />
                  <SkeletonRow col={4} />
                  <SkeletonRow col={4} />
                  <SkeletonRow col={4} />
                </>
              ) : (
                students.map((student) => (
                  <Table.Row
                    key={student.student_id}
                    className="hover:bg-gray-50"
                  >
                    <Table.Cell>{student.student_name}</Table.Cell>
                    <Table.Cell>{student.student_email}</Table.Cell>
                    <Table.Cell>{student.student_rollno}</Table.Cell>
                    <Table.Cell>{student.cluster_names}</Table.Cell>
                  </Table.Row>
                ))
              )}
            </Table.Body>
          </Table>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AssignmentNotAttendee;
