import React from "react";
import { FaRegClock } from "react-icons/fa";
import { Link } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  useGetCourseProgressQuery,
  useGetStudentByEmailIdQuery,
} from "../../../graphql/generated.tsx";

const CourseCard = ({
  name,
  short_description,
  time_period,
  course_id,
  clusterId,
  level,
}) => {
  const email = JSON.parse(localStorage.getItem("user"))?.email;

  const { data: studentData } = useGetStudentByEmailIdQuery({
    variables: { email },
  });
  const purchasedCourses =
    studentData?.getStudentByEmailId?.purchasedCourses || [];
  const isPaid = purchasedCourses.includes(course_id);
  const { data } = useGetCourseProgressQuery({
    variables: {
      studentId: studentData?.getStudentByEmailId.id,
      courseId: course_id,
      isPaid: isPaid,
    },
  });

  const progressPercentage = Math.round(
    data?.getCourseProgress?.percentageCompleted || 0
  );

  return (
    <Link
      to={`/student/courses/${clusterId}/${course_id}`}
      className="shadow-lg rounded-lg max-md:w-full w-84 bg-white flex flex-col justify-between hover:shadow-xl hover:-translate-y-2 transition-transform duration-300 cursor-pointer overflow-hidden"
      style={{ width: "22rem" }}
    >
      {/* Card Header Section */}
      <div className="relative p-4 md:p-6 bg-gradient-to-r from-blue-50 to-white h-full">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-semibold text-gray-800 truncate">
            {name}
          </h3>
          <div className="w-12 h-12">
            <CircularProgressbar
              value={progressPercentage}
              text={`${progressPercentage}%`}
              styles={buildStyles({
                textSize: "24px",
                textColor: "#0E3D5D", // Tailwind's blue-800 color
                pathColor: "#0E3D5D",
                trailColor: "#D1D5DB", // Tailwind's gray-300 color
              })}
            />
          </div>
        </div>
        <p className="text-sm text-gray-600 mt-2 max-sm:line-clamp-2">
          {short_description}
        </p>
      </div>

      {/* Divider */}
      <hr className="border-gray-200" />

      {/* Card Footer Section */}
      <div className="p-4 bg-white">
        <div className="flex justify-between items-center mb-2">
          <span className="bg-blue-100 text-blue-800 text-sm font-medium px-3 py-1 rounded-lg">
            {level}
          </span>
          <p className="flex items-center text-sm text-blue-600 font-medium">
            <FaRegClock className="mr-2" /> {time_period} hours
          </p>
        </div>
      </div>
    </Link>
  );
};

export default CourseCard;
