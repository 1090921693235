import React, { useEffect } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { SlSizeFullscreen } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";


const BackButton = ({ setFullScreen, isFullScreen }) => {
  const location = useLocation();
  console.log(location.state);
  const navigate = useNavigate();

  console.log(location.state);
  

  const handleBack = () => {
    const fromPath = location?.state?.fromPath

    if (fromPath) {
      navigate(fromPath, {state: {pageNumber: location?.state?.pageNumber}});
    } else {
      window.history.back();
    }
  };

  return (
    <div className="flex justify-between items-center mb-6">
      <button
        onClick={handleBack}
        className="text-gray-500 hover:text-gray-700 focus:outline-none w-[100px]"
      >
        <span className="gap-2 text-sm font-medium flex items-center">
          <IoMdArrowRoundBack className="h-6 w-6" />
          Go Back
        </span>
      </button>
      <button
        onClick={() => setFullScreen(!isFullScreen)}
        className="hidden md:block"
      >
        <SlSizeFullscreen className="h-6 w-6" />
      </button>
    </div>
  );
};

export default BackButton;
