const SubmitModel = ({ output, question }) => {
  const totalPassed = output?.testCasePassed.count;
  const totalFailed = output?.testCaseFailed.count;
  const totalTestCases = totalPassed + totalFailed;
  const allPassed = totalFailed === 0;
  const hasError = output?.compilationOutput?.stderr;

  return (
    <div className="p-5 bg-white rounded-md shadow-lg border border-gray-300 mt-5">
      {hasError ? (
        <div className="text-red-600">
          <p>Error during compilation:</p>
          <div className="overflow-x-auto">
            <pre>{hasError}</pre>
          </div>
        </div>
      ) : (
        <>
          <div className="m-8 text-center">
            <p className="text-xl font-bold">Test Case Summary</p>
            <p className="text-md text-gray-600">Total Test Cases: {totalTestCases}</p>
          </div>
          <div className="grid gap-7 sm:grid-cols-2 lg:grid-cols-2">
            <div className="flex flex-col items-center justify-center p-4 bg-green-100 rounded-lg shadow-md">
              <div className="text-2xl font-bold text-green-600">{totalPassed}</div>
              <div className="text-md text-gray-700">Passed Test Cases</div>
              <div className="text-sm text-gray-500">Out of {totalTestCases}</div>
            </div>
            <div className="flex flex-col items-center justify-center p-4 bg-red-100 rounded-lg shadow-md">
              <div className="text-2xl font-bold text-red-600">{totalFailed}</div>
              <div className="text-md text-gray-700">Failed Test Cases</div>
              <div className="text-sm text-gray-500">Out of {totalTestCases}</div>
            </div>
          </div>
          {allPassed && (
            <p className="mt-5 text-center text-green-600 font-semibold">
              All test cases passed!
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default SubmitModel;
