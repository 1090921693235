import { Avatar, Button, Dropdown, Navbar } from "flowbite-react";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { auth } from "../../utils/firebase";
import { Link } from "react-router-dom";

function NavBar({ isLogin }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const userRole = user?.reloadUserInfo?.customAttributes
    ? JSON.parse(user?.reloadUserInfo?.customAttributes).role
    : null;

  const handleSignOut = () => {
    auth
      .signOut()
      .then(() => {
        console.log("User signed out");
        localStorage.removeItem("idtoken");
        localStorage.removeItem("studentData");
        localStorage.removeItem("campusDetails");
        localStorage.removeItem("staffData");
        navigate("/login");
      })
      .catch((error) => {
        console.error("Sign out error:", error);
      });
  };

  return (
    <Navbar
      fluid
      className="fixed top-0 z-30 w-screen border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700 items-center"
    >
      <Navbar.Brand href="/">
        <img
          src="/favicon.ico"
          className="mr-3 h-7 sm:h-9"
          alt="Better Tomorrow Logo"
        />
        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white text-primary">
          Better Tomorrow
        </span>
      </Navbar.Brand>
      <div className="flex md:order-2 ">
        {isLogin && (
          <a
            href="/contactus"
            className="font-semibold text-primary hover:text-indigo-500 mr-10"
          >
            Contact Us
          </a>
        )}
        {!user ? (
          !isLogin ? (
            <Button href="/login" className="bg-primary text-white p-0">
              Login
            </Button>
          ) : null
        ) : (
          <div className="flex md:order-2">
            <Dropdown
              arrowIcon={false}
              inline
              label={<Avatar placeholderInitials="BT" rounded />}
            >
              <Dropdown.Header>
                <span className="block text-sm">
                  {user.displayName}
                  <span className="font-light ml-1">({userRole})</span>
                </span>
                <span className="block truncate text-sm font-medium">
                  {user.email}
                </span>
              </Dropdown.Header>
              {/* <Dropdown.Item
                href={
                  userRole === "bt-admin" || userRole === "staff"
                    ? "/admin"
                    : "/student/assignments"
                }
              >
                Leaderboard
              </Dropdown.Item> */}
              {(userRole === "staff" || userRole === "student") && (
                <Link
                  to={
                    userRole === "staff"
                      ? "/staff/my-profile"
                      : "/student/my-profile"
                  }
                >
                  <Dropdown.Item>Profile</Dropdown.Item>
                </Link>
              )}
              {userRole === "student" && (
                <Link to={"/student/orders"}>
                  <Dropdown.Item>Orders</Dropdown.Item>
                </Link>
              )}

              <Dropdown.Item onClick={handleSignOut}>Sign out</Dropdown.Item>
            </Dropdown>
          </div>
        )}
        {/* <Navbar.Toggle /> */}
      </div>
      {/* <Navbar.Collapse>
        {userRole === "student" && (
          <> */}
      {/* <Navbar.Link
              href="/"
              className={`${
                location.pathname === "/" ? "text-primary" : "text-gray-600"
              }`}
            >
              Home
            </Navbar.Link> */}
      {/* <Navbar.Link
              href="/student/courses"
              className={`${
                location.pathname.startsWith("/student/courses") ||
                location.pathname.startsWith("/student/courses")
                  ? "text-primary"
                  : "text-gray-600"
              }`}
            >
              Practice
            </Navbar.Link>
            <Navbar.Link
              href="/assignments"
              className={`${
                location.pathname.startsWith("/assignments") ||
                location.pathname.startsWith("/assignments")
                  ? "text-primary"
                  : "text-gray-600"
              }`}
            >
              Assignments
            </Navbar.Link> */}
      {/* </>
        )}
      </Navbar.Collapse> */}
    </Navbar>
  );
}

export default NavBar;
