import React, { useState } from "react";
import { FaRegClock } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useRazorpay } from "react-razorpay";
import {
  useCreateRazorpayOrderMutation,
  useSavePaymentDetailsMutation,
} from "../../../graphql/generated.tsx";
import { handlePayment } from "../../../utils/paymentHelper";
import { Modal, Button as FlowbiteButton } from "flowbite-react";

const PricingCard = ({
  name,
  short_description,
  time_period,
  course_id,
  clusterId,
  price,
  student_id,
  level,
  categories,
  whyChoose,
  benefits,
  dis_price,
  features,
}) => {
  const navigate = useNavigate();
  const { Razorpay } = useRazorpay();
  const [createRazorpayOrder] = useCreateRazorpayOrderMutation();
  const [savePaymentDetails] = useSavePaymentDetailsMutation();
  const [openModal, setOpenModal] = useState(false);

  const handlePaymentSuccess = async (response) => {
    try {
      const result = await savePaymentDetails({
        variables: {
          createPaymentInput: {
            course_id,
            cluster_id: clusterId,
            order_id: response.razorpay_order_id,
            payment_id: response.razorpay_payment_id,
            student_id,
            amount_paid: dis_price,
          },
        },
      });

      if (result?.data) {
        toast.success("Payment Successful!");
        window.location.reload();
      } else {
        toast.error("Error saving payment details.");
      }
    } catch (error) {
      console.error("Error saving payment details:", error);
      alert(
        "Payment successful, but saving details failed. Please contact support."
      );
    }
  };

  const handlePaymentFailure = (error) => {
    console.error("Payment Failure:", error);
    toast.error("Payment Failed.");
  };

  const handlePayNow = () => {
    handlePayment({
      courseId: course_id,
      createRazorpayOrder,
      Razorpay,
      onSuccess: handlePaymentSuccess,
      onFailure: handlePaymentFailure,
    });
  };

  return (
    <>
      {/* Redesigned Modal */}
      <Modal show={openModal} onClose={() => setOpenModal(false)} size="lg">
        <Modal.Header>
          <div className="flex items-center space-x-2 from-primary to-primary-dark p-4 rounded-t-md w-full">
            <FaRegClock className="text-black" size={20} />
            <h2 className="text-xl font-semibold">Secure Your Enrollment</h2>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-6 p-4">
            <div>
              <h3 className="text-lg font-bold text-gray-800">
                Why This Course?
              </h3>
              <p className="text-gray-600">{whyChoose}</p>
            </div>
            <div className="border-t border-gray-200 pt-4">
              <h3 className="text-lg font-bold text-gray-800 mb-3">
                Payment Summary
              </h3>
              <div className="flex justify-between items-center mb-2">
                <span className="text-sm text-gray-500">Price:</span>
                <span className="text-sm">₹{dis_price}</span>
              </div>
              <div className="flex justify-between items-center mb-2">
                <span className="text-sm text-gray-500">Tax (18%) :</span>
                <span className="text-sm">₹{dis_price * 0.18}</span>
              </div>
              <div className="flex justify-between items-center mt-4">
                <span className="text-lg font-bold text-gray-800">Total:</span>
                <span className="text-lg font-bold text-gray-800">
                  ₹{(dis_price * 1.18).toFixed(2)}
                </span>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="flex flex-col items-center space-y-2 p-4">
          <FlowbiteButton
            onClick={handlePayNow}
            className="w-full bg-gradient-to-r from-primary to-primary-dark text-white py-2 rounded-lg shadow-md hover:opacity-90 transition duration-200"
          >
            Purchase Now
          </FlowbiteButton>
          <p className="text-xs text-gray-500">
            Start learning risk-free today!
          </p>
        </Modal.Footer>
      </Modal>

      {/* Redesigned Pricing Card */}
      <div className="max-w-sm bg-white rounded-xl shadow-lg overflow-hidden border-t-4 border-primary transition transform hover:scale-105" style={{ width: "22rem" }}>
        <div className="p-6">
          <div className="mb-4">
            <h2 className="text-lg font-extrabold text-primary">{name}</h2>
            <p className="mt-2 text-sm text-gray-600">{short_description}</p>
          </div>
          <div className="flex items-center justify-between mb-4">
            <span className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-xs font-medium">
              {level}
            </span>
            <div className="flex items-center">
              <FaRegClock className="text-primary mr-1" />
              <span className="text-sm text-green-600 font-semibold">
                Lifetime Access
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-4">
            <div>
              <span className="text-3xl font-bold text-primary">
                ₹{dis_price}
              </span>
              <span className="text-base text-gray-400 line-through ml-2">
                ₹{price}
              </span>
            </div>
            <div className="text-right">
              <span className="block text-sm text-green-600 font-semibold">
                Save ₹{price - dis_price}
              </span>
              <span className="block text-xs text-blue-600 font-medium">
                {Math.round(((price - dis_price) / price) * 100)}% Off
              </span>
            </div>
          </div>
          <div className="mb-6">
            <h3 className="text-lg font-bold text-gray-800 mb-2">Benefits</h3>
            <ul className="space-y-2">
              {benefits?.map((benefit, index) => (
                <li key={index} className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-green-500 mr-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <span className="text-sm text-gray-700">{benefit}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex gap-4">
            <button
              onClick={() =>
                navigate(`/student/courses/${clusterId}/${course_id}`)
              }
              className="flex-1 py-2 px-4 border border-gray-300 rounded-lg text-gray-700 font-medium hover:bg-gray-100 transition duration-200"
            >
              View Overview
            </button>
            <button
              onClick={() => setOpenModal(true)}
              className="flex-1 py-2 px-4 bg-primary text-white rounded-lg font-medium hover:bg-primary-dark transition duration-200"
            >
              Buy Now
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingCard;
