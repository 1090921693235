import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Spinner } from "flowbite-react";
import { useGetQuestionCompletedQuery } from "../../../graphql/generated.tsx";
import { GrCircleInformation } from "react-icons/gr";

ChartJS.register(ArcElement, Tooltip, Legend);

const StudentCourseProgression = ({ studentData, isAdmin, paidDashboard }) => {
  const student_id = studentData ? studentData.id : null;

  const {
    data: studentQuestion,
    loading,
    error,
  } = useGetQuestionCompletedQuery({
    variables: { student_id, is_paid: !paidDashboard },
  });

  console.log({ studentQuestion });

  const isDataAvailable =
    studentQuestion &&
    studentQuestion.getQuestionCompleted &&
    studentQuestion.getQuestionCompleted.easy &&
    studentQuestion.getQuestionCompleted.medium &&
    studentQuestion.getQuestionCompleted.hard;

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[345px]">
        <Spinner />
      </div>
    );
  }

  if (error || !isDataAvailable) {
    return (
      <div className="flex justify-center items-center min-h-[345px] text-red-500">
        No data available
      </div>
    );
  }

  const donutData = {
    labels: ["Easy", "Medium", "Hard"],
    datasets: [
      {
        data: [
          studentQuestion?.getQuestionCompleted?.easy?.completed || 0,
          studentQuestion?.getQuestionCompleted?.medium?.completed || 0,
          studentQuestion?.getQuestionCompleted?.hard?.completed || 0,
        ],
        backgroundColor: ["#0E5B54", "#5B2E0E", "#0E3B5B"],
        hoverBackgroundColor: ["#0E5B54", "#5B2E0E", "#0E3B5B"],
      },
    ],
  };

  const donutOptions = {
    cutout: "80%",
    plugins: {
      legend: {
        display: false,
      },
    },
    interaction: {
      mode: "nearest",
    },
  };

  const totalCompleted =
    (studentQuestion?.getQuestionCompleted?.easy?.completed || 0) +
    (studentQuestion?.getQuestionCompleted?.medium?.completed || 0) +
    (studentQuestion?.getQuestionCompleted?.hard?.completed || 0);

  const percentage = totalCompleted;

  if (totalCompleted === 0) {
    return (
      <>
        <div
          className="bg-gray-200 py-8 rounded-md shadow-md flex flex-col items-center px-5 justify-center items-center w-full"
          style={{ height: "345px" }}
        >
          <GrCircleInformation className="h-10 w-10 text-primary" />
          <div className="text-primary text-lg font-semibold text-center p-5">
            {isAdmin
              ? "This student has not yet started the course. Please encourage them to take the first step!"
              : "Start your learning journey today! Track your progress as you engage with the course material and achieve your goals."}
          </div>
        </div>
      </>
    );
  }

  return (
    <div
      className="bg-white py-8 rounded-lg shadow-md flex flex-col items-center px-28 max-md:px-2 w-full"
      style={{ height: "345px" }}
    >
      <div
        className="relative w-full mb-5 flex justify-center items-center"
        style={{ height: "200px" }}
      >
        <Doughnut data={donutData} options={donutOptions} />
        <div className="absolute flex flex-col items-center justify-center text-xl font-bold text-gray-800">
          {percentage}
          <br />
          <span className="text-sm font-light">Questions Solved</span>
        </div>
      </div>

      <div className="flex justify-around w-full">
        <div className="text-center">
          <div className="text-lg font-bold">
            {studentQuestion?.getQuestionCompleted?.easy?.completed || 0} /{" "}
            {studentQuestion?.getQuestionCompleted?.easy?.total || 0}
          </div>
          <div className="text-xs text-gray-500 mt-1">
            <span
              className="inline-block px-2 py-1 rounded-md text-white"
              style={{ backgroundColor: "#0E5B54" }}
            >
              Easy
            </span>
          </div>
        </div>
        <div className="text-center">
          <div className="text-lg font-bold">
            {studentQuestion?.getQuestionCompleted?.medium?.completed || 0} /{" "}
            {studentQuestion?.getQuestionCompleted?.medium?.total || 0}
          </div>
          <div className="text-xs text-gray-500 mt-1">
            <span
              className="inline-block px-2 py-1 rounded-md text-white"
              style={{ backgroundColor: "#5B2E0E" }}
            >
              Medium
            </span>
          </div>
        </div>
        <div className="text-center">
          <div className="text-lg font-bold">
            {studentQuestion?.getQuestionCompleted?.hard?.completed || 0} /{" "}
            {studentQuestion?.getQuestionCompleted?.hard?.total || 0}
          </div>
          <div className="text-xs text-gray-500 mt-1">
            <span
              className="inline-block px-2 py-1 rounded-md text-white"
              style={{ backgroundColor: "#0E3B5B" }}
            >
              Hard
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentCourseProgression;
