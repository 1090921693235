import React, { useState, useEffect } from "react";
import {
  useGetAllCoursesByClusterLazyQuery,
  useGetAllCoursesByClusterQuery,
  useGetCampusQuery,
  useGetClustersByIdsAndCampusQuery,
  useGetCourseLazyQuery,
  useGetStudentByEmailIdQuery,
} from "../../../graphql/generated.tsx";
import { CiCircleChevLeft, CiCircleChevRight } from "react-icons/ci";
import { FaArrowLeft, FaArrowRight, FaRegClock } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const RecommendedCourseCards = ({ courseid, setIsRecommendedEmpty }) => {
  const [allCoursesData, setAllCoursesData] = useState([]);
  const [loadingCourses, setLoadingCourses] = useState(true);
  const navigate = useNavigate();
  const email = JSON.parse(localStorage.getItem("user"))?.email;

  const { data: studentData } = useGetStudentByEmailIdQuery({
    variables: { email },
  });
  const purchasedCourses =
    studentData?.getStudentByEmailId?.purchasedCourses || [];
  const campusId = studentData?.getStudentByEmailId?.campusId;
  const { data: onlineCourse } = useGetAllCoursesByClusterQuery({
    variables: {
      clusterId: "e27a3599-01e6-4e13-a195-9c4f2a0a9abc",
      campusName: "Better Tomorrow",
    },
  });
  const { data: campusData } = useGetCampusQuery({
    variables: { id: campusId },
    skip: !campusId,
  });

  const campusName = campusData?.campus?.name || "";
  const clusterIds = studentData?.getStudentByEmailId?.clusterIds || [];

  const { data: clustersData } = useGetClustersByIdsAndCampusQuery({
    variables: { clusterIds, campusName },
    skip: !campusName || clusterIds.length === 0,
  });

  const [getCoursesByCluster] = useGetAllCoursesByClusterLazyQuery();
  const [getCourse] = useGetCourseLazyQuery();

  useEffect(() => {
    const fetchCourses = async () => {
      setLoadingCourses(true);
      try {
        if (clustersData?.getClustersByIdsAndCampus?.length > 0) {
          const coursesPromises = clustersData.getClustersByIdsAndCampus.map(
            (cluster) =>
              getCoursesByCluster({
                variables: { clusterId: cluster.id, campusName },
              }).then((response) => ({
                cluster_id: cluster.id,
                clusterName: cluster.name,
                courses: response.data?.getAllCoursesByCluster || [],
              }))
          );

          const allCourses = await Promise.all(coursesPromises);

          const purchasedCoursesPromises = purchasedCourses.map(
            async (purchased) => {
              const response = await getCourse({
                variables: { id: purchased.course_id },
              });

              return {
                id: purchased.course_id,
                name: response.data?.getCourse?.name || "Purchased Course",
                description: response.data?.getCourse?.description || "",
                short_description: response.data?.getCourse?.short_description,
                time_period: response.data?.getCourse?.time_period,
                level: response.data?.getCourse?.level,
                isPurchased: true,
              };
            }
          );

          const purchasedCoursesData = await Promise.all(
            purchasedCoursesPromises
          );

          const purchasedCoursesCluster = {
            cluster_id: "e27a3599-01e6-4e13-a195-9c4f2a0a9abc",
            clusterName: "Purchased Courses",
            courses: purchasedCoursesData,
          };

          setAllCoursesData([...allCourses, purchasedCoursesCluster]);
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      } finally {
        setLoadingCourses(false);
      }
    };

    fetchCourses();
  }, [clustersData, campusName, getCoursesByCluster, getCourse]);

  const purchasedCourseIds = purchasedCourses.map((course) => course.course_id);
  const clusterCourseIds = allCoursesData.flatMap((cluster) =>
    cluster.courses.map((course) => course.id)
  );

  const filteredOnlineCourses =
    onlineCourse?.getAllCoursesByCluster?.filter(
      (course) =>
        !purchasedCourseIds.includes(course.id) &&
        !clusterCourseIds.includes(course.id)
    ) || [];

  const courses = filteredOnlineCourses.filter(
    (course) => course.id !== courseid
  );
  useEffect(() => {
    if (onlineCourse && courses.length === 0) {
      setIsRecommendedEmpty(true);
    }
  }, [onlineCourse]);

  // const filteredOnlineCourses = [
  //   {
  //     id: "course1",
  //     name: "Introduction to React",
  //     description:
  //       "Learn the basics of React including components, state, and props.",
  //     short_description: "Learn the basics of React.",
  //     time_period: "4 weeks",
  //     level: "Beginner",
  //   },
  //   {
  //     id: "course2",
  //     name: "Advanced JavaScript",
  //     description:
  //       "Deep dive into advanced JavaScript concepts like closures, promises, and async/await.",
  //     short_description: "Master advanced JavaScript concepts.",
  //     time_period: "6 weeks",
  //     level: "Intermediate",
  //   },
  //   {
  //     id: "course3",
  //     name: "Full-Stack Web Development",
  //     description:
  //       "Build full-stack applications using React, Node.js, and MongoDB.",
  //     short_description: "Learn full-stack web development.",
  //     time_period: "8 weeks",
  //     level: "Advanced",
  //   },
  //   {
  //     id: "course4",
  //     name: "Data Structures and Algorithms",
  //     description:
  //       "Understand and implement various data structures and algorithms in JavaScript.",
  //     short_description: "Master data structures and algorithms.",
  //     time_period: "5 weeks",
  //     level: "Intermediate",
  //   },
  // ];

  return (
    <>
      {courses.length != 0 && (
        <>
          <h1 className="text-xl font-bold mb-2">Recommended Courses</h1>
          <div className="w-full h-full overflow-y-auto space-y-4">
            {courses.map((item) => (
              <div
                key={item.id}
                className="bg-white p-4 rounded-lg shadow-md border border-black flex flex-col justify-between hover:shadow-lg transition-shadow duration-300 min-h-[200px]"
              >
                <h3 className="font-bold text-lg">{item.name}</h3>
                <div className="flex w-full flex-wrap sm:flex-nowrap justify-between items-center gap-4 py-2">
                  <span className="inline-block bg-blue-100 text-blue-800 text-sm font-medium px-3 py-1 rounded">
                    {item.level}
                  </span>
                </div>
                <p className="text-gray-600 mt-2 text-sm">
                  {item.short_description}
                </p>
                <div className="flex-grow"></div>
                <button
                  className="mt-3 bg-gradient-to-r from-blue-500 to-blue-700 text-white font-semibold px-4 py-1.5 w-[40%] rounded-lg shadow-md hover:from-blue-600 hover:to-blue-800 transition-all duration-300 w-full"
                  onClick={() =>
                    navigate(
                      `/student/courses/e27a3599-01e6-4e13-a195-9c4f2a0a9abc/${item.id}`
                    )
                  }
                >
                  Avail Now
                </button>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default RecommendedCourseCards;
