import React, { useEffect, useState } from "react";
import CoursesListing from "./Components/StudentCoursesListing";
import {
  useGetStudentByEmailIdQuery,
  useGetCampusQuery,
  useGetClustersByIdsAndCampusQuery,
  useGetAllCoursesByClusterLazyQuery,
  useGetAllCoursesByClusterQuery,
  useGetCourseLazyQuery,
} from "../../graphql/generated.tsx";
import { Alert } from "flowbite-react";
import StudentLayout from "../Student/Layout/index.jsx";

function Practice() {
  const [allCoursesData, setAllCoursesData] = useState([]);
  const [loadingCourses, setLoadingCourses] = useState(true);
  const email = JSON.parse(localStorage.getItem("user"))?.email;

  const {
    data: studentData,
    loading: studentLoading,
    error: studentError,
  } = useGetStudentByEmailIdQuery({ variables: { email } });

  const purchasedCourses =
    studentData?.getStudentByEmailId?.purchasedCourses || [];
  const campusId = studentData?.getStudentByEmailId?.campusId;

  const {
    data: campusData,
    loading: campusLoading,
    error: campusError,
  } = useGetCampusQuery({ variables: { id: campusId }, skip: !campusId });

  const campusName = campusData?.campus?.name || "";
  const clusterIds = studentData?.getStudentByEmailId?.clusterIds || [];

  const {
    data: clustersData,
    loading: clustersLoading,
    error: clustersError,
  } = useGetClustersByIdsAndCampusQuery({
    variables: { clusterIds, campusName },
    skip: !campusName || clusterIds.length === 0,
  });

  const { data: onlineCourse } = useGetAllCoursesByClusterQuery({
    variables: {
      clusterId: "e27a3599-01e6-4e13-a195-9c4f2a0a9abc",
      campusName: "Better Tomorrow",
    },
  });

  const purchasedCourseIds = purchasedCourses.map((course) => course.course_id);
  const clusterCourseIds = allCoursesData.flatMap((cluster) =>
    cluster.courses.map((course) => course.id)
  );

  const filteredOnlineCourses =
    onlineCourse?.getAllCoursesByCluster?.filter(
      (course) =>
        !purchasedCourseIds.includes(course.id) &&
        !clusterCourseIds.includes(course.id)
    ) || [];

  const [getCoursesByCluster] = useGetAllCoursesByClusterLazyQuery();
  const [getCourse] = useGetCourseLazyQuery();

  useEffect(() => {
    const fetchCourses = async () => {
      setLoadingCourses(true);
      try {
        if (clustersData?.getClustersByIdsAndCampus?.length > 0) {
          const coursesPromises = clustersData.getClustersByIdsAndCampus.map(
            (cluster) =>
              getCoursesByCluster({
                variables: { clusterId: cluster.id, campusName },
              }).then((response) => ({
                cluster_id: cluster.id,
                clusterName: cluster.name,
                courses: response.data?.getAllCoursesByCluster || [],
              }))
          );

          const allCourses = await Promise.all(coursesPromises);

          const purchasedCoursesPromises = purchasedCourses.map(
            async (purchased) => {
              const response = await getCourse({
                variables: { id: purchased.course_id },
              });

              return {
                id: purchased.course_id,
                name: response.data?.getCourse?.name || "Purchased Course",
                description: response.data?.getCourse?.description || "",
                short_description: response.data?.getCourse?.short_description,
                time_period: response.data?.getCourse?.time_period,
                level: response.data?.getCourse?.level,
                isPurchased: true,
              };
            }
          );

          const purchasedCoursesData = await Promise.all(
            purchasedCoursesPromises
          );

          const purchasedCoursesCluster = {
            cluster_id: "e27a3599-01e6-4e13-a195-9c4f2a0a9abc",
            clusterName: "Purchased Courses",
            courses: purchasedCoursesData,
          };

          setAllCoursesData([...allCourses, purchasedCoursesCluster]);
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      } finally {
        setLoadingCourses(false);
      }
    };

    fetchCourses();
  }, [clustersData, campusName, getCoursesByCluster, getCourse]);

  const loader = (
    <div className="flex justify-center items-center h-screen bg-gray-50">
      <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-primary"></div>
    </div>
  );

  const errorMessage = (
    <div className="flex items-center justify-center min-h-screen bg-gray-50">
      <Alert color="failure">
        <span>Error loading data. Please try again later.</span>
      </Alert>
    </div>
  );

  return (
    <StudentLayout>
      <div className="max-md:m-5 lg:m-10 sm:m-4 space-y-8 max-md:ml-5 bg-gray-50 p-5 rounded-lg ">
        {/* Enhanced Heading */}
        <div className="text-center space-y-4 max-md:p-4 p-6">
          <h1 className="text-5xl font-extrabold tracking-wide text-primary max-md:text-3xl">
            Explore All Courses 🚀
          </h1>
          <p className="text-lg text-gray-600 max-md:text-base sm:text-sm">
            Browse through a variety of courses tailored for your success in
            your enrolled clusters.
          </p>
        </div>

        {/* Call-to-Action Section */}
        <div className="flex justify-between items-center bg-blue-100 rounded-lg p-6 shadow-lg max-md:flex-col max-md:space-y-4">
          <div className="space-y-2 max-md:text-center">
            <h2 className="text-2xl font-bold text-primary max-md:text-xl">
              Looking for something extra?
            </h2>
            <p className="text-primary max-md:text-sm sm:text-xs">
              Don't miss out on our specially designed online courses to take
              your skills to the next level!
            </p>
          </div>
          <button
            onClick={() =>
              document
                .getElementById("online-courses-section")
                ?.scrollIntoView({ behavior: "smooth" })
            }
            className="px-6 py-3 bg-primary text-white rounded-lg font-medium shadow transition max-md:w-full sm:text-sm"
          >
            Discover Courses
          </button>
        </div>

        {/* Loader/Error Handling */}
        {studentLoading || campusLoading || clustersLoading || loadingCourses
          ? loader
          : null}
        {studentError || campusError || clustersError ? errorMessage : null}

        {/* All Courses Section */}
        {allCoursesData.map(
          (clusterCourses, index) =>
            clusterCourses.courses.length > 0 && (
              <div key={index} className="space-y-3 mt-14">
                <h2 className="text-3xl font-semibold text-primary border-primary pl-4 max-md:text-xl">
                  {clusterCourses.clusterName}
                </h2>
                <div>
                  <CoursesListing
                    courseData={clusterCourses.courses}
                    clusterId={clusterCourses.cluster_id}
                    student_id={studentData?.getStudentByEmailId?.id}
                  />
                </div>
              </div>
            )
        )}

        {filteredOnlineCourses.length !== 0 && (
          <div className="space-y-3" id="online-courses-section">
            <h2 className="text-3xl font-semibold text-primary border-primary pl-4 max-md:text-xl mt-[54px]">
              Recommended Courses
            </h2>
            <div>
              <CoursesListing
                courseData={filteredOnlineCourses}
                clusterId={"e27a3599-01e6-4e13-a195-9c4f2a0a9abc"}
                student_id={studentData?.getStudentByEmailId?.id}
                paid
              />
            </div>
          </div>
        )}
      </div>

      {/* Online Courses Section */}
      {/* {filteredOnlineCourses.length !== 0 && (
        <div
          id="online-courses-section"
          className="max-md:m-5 lg:m-10 sm:m-4 space-y-8 max-md:ml-5 bg-gray-50 p-5 rounded-lg"
        >
          <div className="text-center space-y-2 max-md:p-4 p-6">
            <h1 className="text-5xl font-extrabold text-primary max-md:text-3xl">
              Discover Your Next Big Opportunity 🌟
            </h1>
            <div className="text-center mb-4">
              <p className="text-lg font-medium text-gray-700 max-md:text-base sm:text-sm">
                Transform your career with expertly crafted courses designed for
                success.
              </p>
              <p className="text-sm text-gray-500 mt-1 max-md:text-xs">
                Stay ahead of the curve with exclusive content, practical
                insights, and guidance from industry leaders.
              </p>
              <p className="text-sm text-gray-500 mt-1 max-md:text-xs">
                Start your journey today—don’t let the chance to achieve your
                dreams pass you by.
              </p>
            </div>
          </div>
          <CoursesListing
            courseData={filteredOnlineCourses}
            clusterId={"e27a3599-01e6-4e13-a195-9c4f2a0a9abc"}
            student_id={studentData?.getStudentByEmailId?.id}
            paid
          />
        </div>
      )} */}
    </StudentLayout>
  );
}

export default Practice;
