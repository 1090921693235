export const handlePayment = async ({
  courseId,
  createRazorpayOrder,
  Razorpay,
  onSuccess,
  onFailure,
}) => {
  const orderId = `order_${Date.now()}`;

  try {
    const { data } = await createRazorpayOrder({
      variables: {
        orderId,
        courseId
      },
    });

    const razorpayOrderId = data.createRazorpayOrder;

    const options = {
      key: "rzp_live_dtRNnmf5X1Fs4i",
      currency: "INR",
      name: "CampusHub",
      description: "Test Transaction",
      order_id: razorpayOrderId,
      handler: (response) => {
        console.log("Payment Success:", response);
        if (onSuccess) onSuccess(response);
      },
      prefill: {
        name: "John Doe",
        email: "johndoe@example.com",
        contact: "1234567890",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#0E3D5D",
      },
    };

    const rzp = new Razorpay(options);
    rzp.on("payment.failed", (response) => {
      console.error("Payment Failed:", response);
      if (onFailure) onFailure(response);
    });
    rzp.open();
  } catch (error) {
    console.error("Payment Initialization Error:", error);
    if (onFailure) onFailure(error);
  }
};
