import { Modal, Table, Button as FlowbiteButton } from "flowbite-react";
import React, { useEffect, useState } from "react";
import {
  useCreateRazorpayOrderMutation,
  useGetStudentByEmailIdQuery,
  useSavePaymentDetailsMutation,
} from "../../../graphql/generated.tsx";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { useNavigate } from "react-router-dom";
import { handlePayment } from "../../../utils/paymentHelper.js";
import { toast } from "react-toastify";
import { useRazorpay } from "react-razorpay";

const StudentCategoryCard = (props) => {
  const {
    name,
    description,
    cat_id,
    course_id,
    clusterid,
    number,
    categoryDetails,
    isPaidCourse,
    isPurchasedCourse,
    courseData,
  } = props;
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const email = JSON.parse(localStorage.getItem("user"))?.email;
  const [completedPercentage, setCompletedPercentage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: studentData } = useGetStudentByEmailIdQuery({
    variables: { email },
  });

  const questions = categoryDetails?.questions || [];

  const toggleExpand = (event) => {
    event.stopPropagation(); // Prevent card click from navigating
    setIsExpanded(!isExpanded);
  };

  const handleCardClick = (e) => {
    e.stopPropagation();
    navigate(`/student/courses/${clusterid}/${course_id}/${cat_id}`);
  };

  const handleQuestionClick = (e, question) => {
    e.stopPropagation();

    if (!isPurchasedCourse && isPaidCourse) {
      setIsModalOpen(true);
    } else {
      navigate(
        `/student/courses/question/${clusterid}/${course_id}/${question.id}`
      );
    }
  };

  const [savePaymentDetails] = useSavePaymentDetailsMutation();

  const handlePaymentSuccess = async (response) => {
    try {
      const result = await savePaymentDetails({
        variables: {
          createPaymentInput: {
            course_id: course_id,
            cluster_id: clusterid,
            order_id: response.razorpay_order_id,
            payment_id: response.razorpay_payment_id,
            student_id: studentData?.getStudentByEmailId.id,
            amount_paid: courseData?.dis_price,
          },
        },
      });

      if (result?.data) {
        window.location.reload();
        console.log("Payment details saved successfully:", result.data);
        toast.success("Payment Successful!");
      } else {
        toast.error(
          "Payment details save incomplete:",
          result.errors || result
        );
      }
    } catch (error) {
      console.error("Error saving payment details:", error);
      alert(
        "Payment was successful, but we encountered an issue while saving your payment details. Please contact support if this persists."
      );
    }
  };

  const handlePaymentFailure = (error) => {
    console.error("Payment Failure:", error);
    toast.error("Payment Failed.");
  };

  const [createRazorpayOrder] = useCreateRazorpayOrderMutation();
  const { error, isLoading, Razorpay } = useRazorpay();

  const handlePayNow = (e) => {
    e.stopPropagation();
    handlePayment({
      courseId: course_id,
      createRazorpayOrder,
      Razorpay,
      onSuccess: handlePaymentSuccess,
      onFailure: handlePaymentFailure,
    });
    setIsModalOpen(false);
  };

  return (
    <>
      <div
        className="w-full rounded-xl bg-white p-6 border border-black hover:cursor-pointer hover:shadow-lg hover:scale-80 transition-transform"
        onClick={handleCardClick}
      >
        <div className="flex justify-between items-center">
          <div className="flex gap-5 items-center">
            <div className="w-12 h-12">
              <CircularProgressbar
                value={completedPercentage}
                text={`${number}`}
                styles={buildStyles({
                  textSize: "32px",
                  textColor: "#0E3D5D",
                  pathColor: "#0E3D5D",
                  trailColor: "#D1D5DB",
                })}
              />
            </div>
            <div className="flex justify-center flex-col text-left">
              <h5 className="text-2xl font-bold tracking-tight dark:text-white">
                {name}
              </h5>
              <p className="font-normal text-slate-500 text-md">
                {description}
              </p>
            </div>
          </div>
          <buttton
            onClick={toggleExpand}
            className="rounded-full bg-gray-200 text-black hover:bg-gray-200  p-2 w-10 h-10 flex items-center justify-center hover:cursor-pointer"
          >
            {isExpanded ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 15l7-7 7 7"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            )}
          </buttton>
        </div>

        {isExpanded && questions && (
          <div className="mt-4 bg-white border rounded-lg overflow-y-auto max-md:max-h-64">
            <Table className="table-auto w-full">
              <Table.Head>
                <Table.HeadCell className="bg-gray-100 text-xs sm:text-sm">
                  S.NO
                </Table.HeadCell>
                <Table.HeadCell className="bg-gray-100 text-xs sm:text-sm">
                  Question
                </Table.HeadCell>
                <Table.HeadCell className="bg-gray-100 text-xs sm:text-sm">
                  Difficulty
                </Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                {questions.map((question, index) => {
                  return (
                    <Table.Row key={index} className="text-xs sm:text-sm">
                      <Table.Cell className="max-md:px-2 max-md:py-1">
                        {index + 1}
                      </Table.Cell>
                      <Table.Cell
                        className="max-md:px-2 max-md:py-1 cursor-pointer hover:underline"
                        onClick={(e, question) =>
                          handleQuestionClick(e, question)
                        }
                      >
                        {question.title || "No title available"}
                      </Table.Cell>
                      <Table.Cell className="max-md:px-2 max-md:py-1">
                        {question?.difficulty
                          ? question.difficulty.charAt(0).toUpperCase() +
                            question.difficulty.slice(1).toLowerCase()
                          : "Unknown"}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        )}
      </div>
      <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Modal.Header>
          <h2 className="text-lg font-bold text-gray-900">
            Unlock Your Potential with This Course
          </h2>
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            {/* Course Details */}
            <div>
              <h3 className="font-semibold text-lg text-gray-800">
                Why Choose This Course?
              </h3>
              <p className="text-gray-700">{courseData?.whyChoose}</p>
            </div>

            <div className="border-t border-gray-200 pt-4">
              <h3 className="text-lg font-bold text-gray-800 mb-3">
                Payment Summary
              </h3>
              <div className="flex justify-between items-center mb-2">
                <span className="text-sm text-gray-500">Price:</span>
                <span className="text-sm">₹{courseData?.dis_price}</span>
              </div>
              <div className="flex justify-between items-center mb-2">
                <span className="text-sm text-gray-500">Tax (18%) :</span>
                <span className="text-sm">₹{courseData?.dis_price * 0.18}</span>
              </div>
              <div className="flex justify-between items-center mt-4">
                <span className="text-lg font-bold text-gray-800">Total:</span>
                <span className="text-lg font-bold text-gray-800">
                  ₹{(courseData?.dis_price * 1.18).toFixed(2)}
                </span>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="flex flex-col items-center space-y-2">
          <FlowbiteButton
            className="bg-primary text-white w-full font-medium rounded-md hover:bg-primary-dark transition duration-200"
            onClick={handlePayNow}
          >
            Purchase Now
          </FlowbiteButton>
          <p className="text-xs text-gray-500 mt-2 text-center">
            Start learning risk-free today!
          </p>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StudentCategoryCard;
