import React, { useEffect, useState } from "react";
import {
  useFindAssignmentsForStudentWithFilterLazyQuery,
  useGetAssignmentSubmitLazyQuery,
  useGetCampusQuery,
  useGetStudentByEmailIdQuery,
} from "../../../graphql/generated.tsx";
import { Badge, Card, Spinner, Button, Label } from "flowbite-react";
import { FaBook, FaCalendarAlt, FaCheckCircle, FaSearch } from "react-icons/fa";
import formatDate from "../../Admin/Helpers/formatDate.jsx";
import StudentLayout from "../Layout/index.jsx";
import { useNavigate } from "react-router-dom";
import { startOfWeek, format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Dropdown from "react-multilevel-dropdown";
import { BiSolidDownArrow } from "react-icons/bi";

const AssignmentLeaderboard = () => {
  const navigate = useNavigate();
  const [filterApplied, setFilterApplied] = useState("week");
  const email = JSON.parse(localStorage.getItem("user"))?.email;
  const [customDateRangeApplied, setCustomDateRangeApplied] = useState(false);

  const { data: student, loading: studentLoading } =
    useGetStudentByEmailIdQuery({
      variables: { email },
    });
  const { data: campus } = useGetCampusQuery({
    variables: { id: student?.getStudentByEmailId?.campusId },
    skip: !student?.getStudentByEmailId,
  });

  const [filterType, setFilterType] = useState("week");
  const [customDateRange, setCustomDateRange] = useState({
    startDate: "",
    endDate: "",
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [
    getAssignments,
    { data: assignmentsData, loading: assignmentsLoading },
  ] = useFindAssignmentsForStudentWithFilterLazyQuery();
  const [getAssignmentSubmit] = useGetAssignmentSubmitLazyQuery();
  const [completedAssignmentMap, setCompletedAssignmentMap] = useState({});

  const handleFilterChange = (type) => {
    setCustomDateRangeApplied(null);
    setFilterType(type);
    setFilterApplied(type);
    if (type === "custom") {
      const currentWeekStart = startOfWeek(new Date(), { weekStartsOn: 1 });
      const today = new Date();
      setCustomDateRange({
        startDate: format(currentWeekStart, "yyyy-MM-dd"),
        endDate: format(today, "yyyy-MM-dd"),
      });
    } else {
      setCustomDateRange({ startDate: "", endDate: "" });
      getAssignments({
        variables: { email, filterType: type, customDateRange: null },
      });
    }
  };

  useEffect(() => {
    if (email) {
      getAssignments({
        variables: {
          email,
          filterType: customDateRangeApplied ? "custom" : filterType,
          customDateRange: customDateRangeApplied ? customDateRange : null,
        },
      });
    }
  }, [
    email,
    filterType,
    filterApplied,
    customDateRangeApplied,
    getAssignments,
    customDateRangeApplied,
  ]);

  useEffect(() => {
    if (student && assignmentsData) {
      assignmentsData.findAssignmentsForStudentWithFilter.forEach(
        (assignment) => {
          getAssignmentSubmit({
            variables: {
              assignmentId: assignment.id,
              studentId: student?.getStudentByEmailId.id,
              campus_name: campus?.campus.name
            },
          }).then((result) => {
            setCompletedAssignmentMap((prev) => ({
              ...prev,
              [assignment.id]: result?.data?.getAssignmentSubmit?.completed,
            }));
          });
        }
      );
    }
  }, [student, assignmentsData, getAssignmentSubmit, campus]);

  if (studentLoading || assignmentsLoading) {
    return (
      <StudentLayout>
        <div className="flex justify-center items-center h-screen">
          <Spinner size="xl" aria-label="Loading assignments..." />
        </div>
      </StudentLayout>
    );
  }

  const completedAssignments =
    assignmentsData?.findAssignmentsForStudentWithFilter.filter(
      (assignment) => completedAssignmentMap[assignment.id]
    );

  const handleCustomDateRangeChange = (field, value) => {
    setFilterApplied("custom");
    setCustomDateRange((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const applyCustomFilter = () => {
    setCustomDateRangeApplied(true);
    getAssignments({
      variables: { email, filterType: "custom", customDateRange },
    });
    setIsDropdownOpen(false); // Close the dropdown after applying the custom filter
  };
  let title;
  if (filterApplied === "week") {
    title = "Last Week";
  } else if (filterApplied === "month") {
    title = "Last Month";
  } else {
    title = "Custom Range";
  }

  return (
    <StudentLayout>
      <div className="lg:m-10 max-md:ml-4 bg-gray-50 p-5">
        <div className="text-center space-y-4 max-md:p-4 p-6 mb-5">
          <h1 className="text-5xl font-extrabold max-md:text-4xl text-primary animate-fade-in">
            🚀 Assignments Leaderboard
          </h1>
          <p className="text-lg text-gray-700">
            Compete with your peers and climb the leaderboard with every
            completed assignment!
          </p>
        </div>

        <div className="flex justify-between items-center mb-8">
          {filterType === "custom" && (
            <div className="flex items-center space-x-4">
              <input
                type="date"
                value={customDateRange.startDate}
                onChange={(e) =>
                  handleCustomDateRangeChange("startDate", e.target.value)
                }
                className="border border-gray-300 rounded p-2"
                placeholder="Start Date"
              />
              <input
                type="date"
                value={customDateRange.endDate}
                onChange={(e) =>
                  handleCustomDateRangeChange("endDate", e.target.value)
                }
                className="border border-gray-300 rounded p-2"
                placeholder="End Date"
              />
              <Button onClick={applyCustomFilter} className="ml-2 bg-primary">
                Apply
              </Button>
            </div>
          )}
          <div className="ml-auto">
            <Dropdown
              title={
                <span className="flex items-center justify-between">
                  {title}
                  <BiSolidDownArrow className="ml-2 h-3 w-4 mt-0.5" />
                </span>
              }
              className="p-2 border border-primary rounded-md bg-white text-[#2C5DE5]"
              open={isDropdownOpen} // Control the dropdown visibility
              onOpen={() => setIsDropdownOpen(true)} // Open handler
              onClose={() => setIsDropdownOpen(false)} // Close handler
            >
              <Dropdown.Item
                onClick={() => handleFilterChange("week")}
                isActive={filterApplied === "week"}
              >
                Last Week
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleFilterChange("month")}
                isActive={filterApplied === "month"}
              >
                Last Month
              </Dropdown.Item>
              <Dropdown.Item isActive={filterApplied === "custom"}>
                Custom Range
                <Dropdown.Submenu title="Custom Date Range">
                  <div
                    className=" flex flex-col p-4 w-[300px]"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Label className="mb-2">From: </Label>
                    <DatePicker
                      selected={customDateRange.startDate}
                      onChange={(date) =>
                        handleCustomDateRangeChange("startDate", date)
                      }
                      className="border border-gray-300 rounded w-full mb-4"
                      placeholderText="Start Date"
                      dateFormat="yyyy-MM-dd"
                    />
                    <Label className="mb-2">To: </Label>
                    <DatePicker
                      selected={customDateRange.endDate}
                      onChange={(date) =>
                        handleCustomDateRangeChange("endDate", date)
                      }
                      className="border border-gray-300 rounded p-2 w-full mb-4"
                      placeholderText="End Date"
                      dateFormat="yyyy-MM-dd"
                    />
                    <Button
                      onClick={applyCustomFilter}
                      className="bg-primary w-full mb-4"
                    >
                      Apply
                    </Button>
                  </div>
                </Dropdown.Submenu>
              </Dropdown.Item>
            </Dropdown>
          </div>
        </div>

        {completedAssignments?.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {completedAssignments.map((assignment) => (
              <Card
                key={assignment.id}
                className="hover:shadow-lg cursor-pointer transition-transform transform hover:scale-105 rounded-xl"
                onClick={() => navigate(`${assignment.id}`)}
              >
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center">
                    <FaBook className="text-blue-500 mr-2" />
                    <h2 className="text-xl font-semibold text-gray-800">
                      {assignment.name}
                    </h2>
                  </div>
                </div>
                <div className="mb-4">
                  <p className="text-gray-600">
                    {assignment.short_description}
                  </p>
                </div>
                <div className="flex items-center justify-between text-gray-500">
                  <div className="flex items-center">
                    <FaCalendarAlt className="mr-2" />
                    <span>{formatDate(assignment.due_date)}</span>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-[500px] text-center">
            <FaSearch className="text-7xl text-gray-400 mb-4" />
            <h2 className="text-2xl font-bold text-gray-600 mb-2">
              Oops, No Results Found
            </h2>
            <p className="text-gray-500 mb-6 lg:w-[500px]">
              It looks like we couldn't find any matching data. Try refining the
              filter options.
            </p>
          </div>
        )}
      </div>
    </StudentLayout>
  );
};

export default AssignmentLeaderboard;
