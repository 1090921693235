import React, { useState } from "react";
import { Modal, Button, TextInput, Label, Textarea } from "flowbite-react";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  useAllCategoriesQuery,
  useUpdateCourseMutation,
} from "../../../graphql/generated.tsx";
import { WithContext as ReactTags } from "react-tag-input";

const languageOptions = [
  { value: "PYTHON", label: "Python" },
  { value: "C", label: "C" },
  { value: "JAVA", label: "Java" },
  { value: "CPP", label: "C++" },
];

const levelOptions = [
  { value: "Beginner", label: "Beginner" },
  { value: "Intermediate", label: "Intermediate" },
  { value: "Advanced", label: "Advanced" },
];

const EditCourse = ({ id, onClose, setCourseData, courseData }) => {
  const [name, setName] = useState(courseData?.name || "");
  const [duration, setDuration] = useState(courseData?.time_period);
  const [whyChoose, setWhyChoose] = useState(courseData?.whyChoose);
  const [companies, setCompanies] = useState(
    courseData?.companies?.map((cmp) => ({ id: cmp, text: cmp })) || []
  );

  const [disPrice, setDisPrice] = useState(courseData?.dis_price || 0);
  const { data: CategoriesData } = useAllCategoriesQuery();
  const allCategories = CategoriesData?.allCategories || [];
  console.log({ courseData });

  const [short_description, setDescription] = useState(
    courseData?.short_description || ""
  );
  const [price, setPrice] = useState(courseData?.price || 0);
  const [categories, setCategories] = useState(
    courseData?.categories.map((item) => {
      return {
        value: item.id,
        label: item.name,
        description: item.description,
      };
    }) || []
  );
  const [languages, setLanguages] = useState(
    courseData?.languages.map((lang) => ({ value: lang, label: lang })) || []
  );
  const [level, setLevel] = useState(
    levelOptions.find((option) => option.value === courseData?.level) || null
  );
const [benefitInput, setBenefitInput] = useState(""); 
const [benefits, setBenefits] = useState(courseData?.benefits || []);

const handleAddBenefit = () => {
  if (benefitInput.trim()) {
    setBenefits([...benefits, benefitInput.trim()]);
    setBenefitInput(""); 
  }
};

const handleRemoveBenefit = (index) => {
  const updatedBenefits = benefits.filter((_, i) => i !== index);
  setBenefits(updatedBenefits);
};


  const [updateCourse] = useUpdateCourseMutation();

  const handleUpdate = async () => {
    try {
      const filteredCategories = categories.map(
        ({ value, label, description }) => ({
          id: value,
          name: label,
          description,
        })
      );

      await updateCourse({
        variables: {
          updateCourseInput: {
            name,
            whyChoose,
            time_period: parseInt(duration),
            short_description,
            price: parseInt(price),
            dis_price: parseInt(disPrice),
            categories: filteredCategories,
            languages: languages.map((lang) => lang.value),
            level: level?.value,
            companies: companies?.map((company) => company.text),
            benefits,
          },
          id,
        },
      });
      toast.success("Course Updated Successfully");
      onClose();
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  const handleCategoryChange = (selectedOptions) => {
    setCategories(selectedOptions || []);
  };

  const handleLanguageChange = (selectedOptions) => {
    setLanguages(selectedOptions || []);
  };

  const handleLevelChange = (selectedOption) => {
    setLevel(selectedOption);
  };

  const handleDelete = (i) => {
    const newTags = companies.slice(0);
    newTags.splice(i, 1);
    setCompanies(newTags);
  };

  const handleAddition = (tag) => {
    setCompanies([...companies, tag]);
  };

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  return (
    <Modal show={true} onClose={onClose}>
      <Modal.Header>Edit Course</Modal.Header>
      <Modal.Body className="h-[100px]">
        <div className="block">
          <Label htmlFor="name" value="Name :" className="font-semibold" />
          <TextInput value={name} onChange={(e) => setName(e.target.value)} />
        </div>

        <div className="block mt-4">
          <Label
            htmlFor="short_description"
            value="Short Description :"
            className="font-semibold"
          />
          <Textarea
            value={short_description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="block mt-4">
          <Label
            htmlFor="whyChoose"
            value="Why Choose this course ? :"
            className="font-semibold"
          />
          <Textarea
            value={whyChoose}
            onChange={(e) => setWhyChoose(e.target.value)}
          />
        </div>

        <div className="block">
          <Label
            htmlFor="duration"
            value="Duration :"
            className="font-semibold"
          />
          <TextInput
            type="number"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
          />
        </div>

        <div className="block">
          <Label htmlFor="Price" value="Price :" className="font-semibold" />
          <TextInput
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>
        <div className="block">
          <Label
            htmlFor="Discount Price"
            value="Discount Price:"
            className="font-semibold"
          />
          <TextInput
            type="number"
            value={disPrice}
            onChange={(e) => setDisPrice(e.target.value)}
          />
        </div>

        <div className="mb-5">
          <div className="block">
            <Label
              htmlFor="companies"
              value="Companies"
              className="font-semibold"
            />
          </div>
          <ReactTags
            tags={companies}
            delimiters={delimiters}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            autocomplete
            classNames={{
              root: "flex flex-wrap gap-5 w-full",
              rootFocused: "border-blue-500",
              selected: "text-black rounded-full mt-2",
              tag: "bg-primary text-white rounded-xl py-1 px-2 cursor-pointer m-1",
              tagInput: " p-2 rounded",
              inputField: "p-2 rounded bg-blue-100 w-full",
              suggestions: "mt-1 p-1 bg-white border border-gray-300 rounded",
              suggestionActive: "bg-gray-100",
              remove: "text-red-500 ml-2 font-bold",
            }}
          />
        </div>

        <div className="block mt-4">
          <Label
            htmlFor="benefits"
            value="Benefits of the course :"
            className="font-semibold"
          />
          <div className="flex gap-2 items-center">
            <TextInput
              id="benefits"
              placeholder="Enter a benefit"
              value={benefitInput}
              onChange={(e) => setBenefitInput(e.target.value)}
              className="flex-1"
            />
            <Button onClick={handleAddBenefit} className="bg-primary">
              Add
            </Button>
          </div>
          <ul className="mt-2">
            {benefits.map((benefit, index) => (
              <li
                key={index}
                className="flex justify-between items-center bg-gray-100 p-2 rounded mt-1"
              >
                <span>{benefit}</span>
                <Button
                  size="xs"
                  color="failure"
                  onClick={() => handleRemoveBenefit(index)}
                >
                  Remove
                </Button>
              </li>
            ))}
          </ul>
        </div>
        <div className="block mt-4">
          <Label
            htmlFor="languages"
            value="Languages :"
            className="font-semibold"
          />
          <Select
            isMulti
            name="languages"
            options={languageOptions}
            value={languages}
            onChange={handleLanguageChange}
            className="w-full rounded-md mb-4"
          />
        </div>
        <div className="block mt-4">
          <Label
            htmlFor="categories"
            value="Categories :"
            className="font-semibold"
          />
          <Select
            id="categories"
            options={allCategories?.map((category) => ({
              label: category.name,
              value: category.id,
              description: category.description,
            }))}
            isMulti
            value={categories}
            onChange={handleCategoryChange}
            placeholder="Select Categories"
            className="w-full rounded-md mb-4 z-100"
          />
        </div>

        <div className="block mt-4">
          <Label htmlFor="level" value="Level :" className="font-semibold" />
          <Select
            name="level"
            options={levelOptions}
            value={level}
            onChange={handleLevelChange}
            className="w-full rounded-md mb-4"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleUpdate} className="p-0 bg-primary">
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditCourse;
