import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  TextInput,
  Label,
  Checkbox,
  Spinner,
} from "flowbite-react";
import Select from "react-select";

const EditStaffModal = ({
  isOpen,
  onClose,
  onSubmit,
  staffDetails,
  loading,
  clusterOptions,
}) => {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    clusterIds: [],
    isAdminStaff: false,
    mailAutomation: false,
    mailSettings: {
      frequency: "",
      studentFilter: "",
    },
  });

  const frequencyOptions = [
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
  ];

  const studentFilterOptions = [
    {
      value: "all",
      label: "All",
    },
    {
      value: "insights",
      label: "Insights",
    },
  ];

  useEffect(() => {
    if (staffDetails) {
      setFormValues({
        name: staffDetails?.name,
        email: staffDetails?.email,
        clusterIds: staffDetails?.clusterIds?.map((id) => ({
          value: id,
          label: clusterOptions.find((c) => c.id === id)?.name,
        })),
        isAdminStaff: staffDetails.isAdminStaff,
        mailAutomation: staffDetails.mailAutomation || false,
        mailSettings: {
          frequency: staffDetails?.mailSettings?.frequency || "",
          studentFilter: staffDetails?.mailSettings?.studentFilter || "",
        },
      });
    }
  }, [staffDetails, clusterOptions]);

  console.log({ formValues });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleClusterChange = (selectedOptions) => {
    setFormValues((prev) => ({
      ...prev,
      clusterIds: selectedOptions || [],
    }));
  };
  const handleMailSettingsChange = (name, value) => {
    setFormValues({
      ...formValues,
      mailSettings: { ...formValues.mailSettings, [name]: value },
    });
  };

  const handleSubmit = () => {
    onSubmit({
      ...formValues,
      clusterIds: formValues?.clusterIds?.map((cluster) => cluster.value),
    });
  };

  return (
    <Modal show={isOpen} onClose={onClose}>
      <Modal.Header className="text-lg font-semibold">Edit Staff</Modal.Header>
      <Modal.Body>
        <div className="flex flex-col space-y-4">
          <div>
            <Label className="block text-sm font-medium text-gray-700">
              Name
            </Label>
            <TextInput
              type="text"
              name="name"
              value={formValues.name}
              onChange={handleChange}
              placeholder="Enter staff name"
              required
            />
          </div>
          <div>
            <label className="flex items-center space-x-2">
              <Checkbox
                name="isAdminStaff"
                checked={formValues.isAdminStaff}
                onChange={handleChange}
              />
              <span className="text-sm font-medium text-gray-700">
                Admin Staff
              </span>
            </label>
          </div>
          <div>
            <Label className="block text-sm font-medium text-gray-700">
              Email
            </Label>
            <TextInput
              disabled
              type="email"
              name="email"
              value={formValues.email}
              onChange={handleChange}
              placeholder="Enter staff email"
              required
            />
          </div>

          <Label className="block text-sm font-medium text-gray-700 mt-5">
            Enable Mail Automation
          </Label>
          <div className="flex items-center space-x-4 mt-2">
            <label className="flex items-center">
              <input
                type="radio"
                name="mailAutomation"
                value={true}
                checked={formValues.mailAutomation === true}
                onChange={() =>
                  setFormValues((prev) => ({
                    ...prev,
                    mailAutomation: true,
                  }))
                }
              />
              <span className="ml-2 text-sm font-medium text-gray-700">On</span>
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                name="mailAutomation"
                value={false}
                checked={formValues.mailAutomation === false}
                onChange={() =>
                  setFormValues((prev) => ({
                    ...prev,
                    mailAutomation: false,
                  }))
                }
              />
              <span className="ml-2 text-sm font-medium text-gray-700">
                Off
              </span>
            </label>
          </div>
          {formValues.mailAutomation && (
            <>
              <div className="mt-4">
                <Label className="block text-sm font-medium text-gray-700">
                  Frequency
                </Label>
                {frequencyOptions.map((option) => (
                  <label
                    key={option.value}
                    className="flex items-center  space-x-5 mt-2"
                  >
                    <input
                      type="radio"
                      name="frequency"
                      value={option.value}
                      checked={
                        formValues.mailSettings.frequency === option.value
                      }
                      onChange={() =>
                        handleMailSettingsChange("frequency", option.value)
                      }
                      className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                    />
                    <span className="ml-2 text-sm font-medium text-gray-700">
                      {option.label}
                    </span>
                  </label>
                ))}
              </div>
              <div className="mt-4">
                <Label className="block text-sm font-medium text-gray-700">
                  Mail Format
                </Label>
                <div className="flex items-center  space-x-5 mt-2">
                  {studentFilterOptions.map((option) => (
                    <label key={option.value} className="flex items-center">
                      <input
                        type="radio"
                        name="studentFilter"
                        value={option.value}
                        checked={
                          formValues.mailSettings.studentFilter === option.value
                        }
                        onChange={() =>
                          handleMailSettingsChange(
                            "studentFilter",
                            option.value
                          )
                        }
                        className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                      />
                      <span className="ml-2 text-sm font-medium text-gray-700">
                        {option.label}
                      </span>
                    </label>
                  ))}
                </div>
              </div>
            </>
          )}

          {!formValues.isAdminStaff && (
            <div>
              <Label className="block text-sm font-medium text-gray-700">
                Clusters
              </Label>
              <Select
                isMulti
                value={formValues.clusterIds}
                onChange={handleClusterChange}
                options={clusterOptions?.map((cluster) => ({
                  value: cluster.id,
                  label: cluster.name,
                }))}
                placeholder="Select clusters"
              />
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSubmit} disabled={loading}>
          {loading && <Spinner size="sm" className="mr-2" />}
          Update
        </Button>
        <Button color="gray" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditStaffModal;
