import { Route } from "react-router-dom";
import SuperAdmin from "../../Modules/Admin/Screens/Bt_Admin/SuperAdmin";
import Feedback from "../../Modules/Admin/Screens/Feedback";
import CampusFeedback from "../../Modules/Admin/Screens/Feedback/CampusFeedback";

const SuperAdminRoutes = [
  <Route key="super-admin" path="admin/bt-admin" element={<SuperAdmin />} />,
  <Route key="feedback" path="admin/feedback" element={<Feedback />} />,
  <Route key="campus-feedback" path="admin/feedback/:college_name" element={<CampusFeedback />} />,
];

export default SuperAdminRoutes;
