import React from "react";
import { Navigate, Route } from "react-router-dom";
import StudentDashboard from "../Modules/Student";
import StudentAssignment from "../Modules/Student/Screens/Assignments";
import StudentCourses from "../Modules/Student/Screens/Courses";
import MyProfile from "../Modules/Student/Screens/MyProfile";
import StudentCourseDetails from "../Modules/Practice/Screens/StudentCourseDetails";
import StudentQuestionListing from "../Modules/Practice/Screens/StudentQuestionListing";
import CompilerScreen from "../Modules/Practice/Screens/CompilerScreen";
import Practice from "../Modules/Practice";
import Assignments from "../Modules/Assignments/Screens/Assignments";
import AssignmentDetails from "../Modules/Assignments/Screens/AssignmentDetails";
import AssignmentTestScreen from "../Modules/Assignments/Screens/AssignmentTestScreen";
import AssignmentTestQuestion from "../Modules/Assignments/Screens/AssignmentTestQuestion";
import AssignmentLeaderboard from "../Modules/Student/Screens/AssignmentLeaderboard";
import Orders from "../Modules/Student/Screens/Orders";

const studentRoutes = [
  <Route key="dashboard" path="/dashboard" element={<StudentDashboard />} />,
  <Route
    key="assignment-leaderboard"
    path="/student/assignment-leaderboard"
    element={<AssignmentLeaderboard />}
  />,
  <Route
    key="assignment-leaderboard-details"
    path="/student/assignment-leaderboard/:assignmentId"
    element={<StudentAssignment />}
  />,
  <Route key="studentCourses" path="/student/courses" element={<Practice />} />,
  <Route
    key="my-profile"
    path="/student/my-profile"
    element={<MyProfile />}
  />,
  <Route
    key={"my-profile"}
    path="/student/orders"
    element={<Orders />}
  />,
  <Route key={"assignments"} path="/assignments" element={<Assignments />} />,
  <Route
    key="assignment-details"
    path="/assignments/:assignmentId"
    element={<AssignmentDetails />}
  />,
  <Route
    key="assignment-test-screen"
    path="/assignments/:assignmentId/test-screen"
    element={<AssignmentTestScreen />}
  />,
  <Route
    key="assignment-test-question"
    path="/assignments/:assignmentId/test-screen/:questionId"
    element={<AssignmentTestQuestion />}
  />,
  <Route
    key="course-details"
    path="/student/courses/:clusterId"
    element={<Practice />}
  />,
  <Route
    key="course-details-specific"
    path="/student/courses/:clusterid/:courseId"
    element={<StudentCourseDetails />}
  />,
  <Route
    key="question-listing"
    path="/student/courses/:clusterid/:courseId/:categoryId"
    element={<StudentQuestionListing />}
  />,
  <Route
    key="compiler-screen"
    path="/student/courses/question/:clusterid/:courseId/:questionId"
    element={<CompilerScreen />}
  />,
];

export default studentRoutes;
