import React, { useEffect, useState } from "react";
import {
  Navigate,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import studentRoutes from "./Routes/StudentRoutes";
import adminRoutes from "./Routes/StaffsRoutes";
import publicRoutes from "./Routes/PublicRoutes";
import authRoutes from "./Routes/AuthRoutes";
import { auth } from "./utils/firebase";
import SuperAdminRoutes from "./Routes/Admin/SuperAdminRoutes.jsx";
import StudentOnboarding from "./Modules/Student/Screens/StudentOnboarding";
import Admin from "./Modules/Admin/Screens/Dashboards/SuperAdminDashboard";
import StaffOnboarding from "./Modules/Admin/Screens/Staffs/StaffOnboarding";
import StaffDashboard from "./Modules/Admin/Screens/Dashboards/StaffDashboard";
import Assignments from "./Modules/Assignments/Screens/Assignments";
import {
  useGetAdminUsingEmailQuery,
  useGetStudentByEmailIdQuery,
} from "./graphql/generated.tsx";
import AssignmentRoutes from "./Routes/Admin/AssignmentRoutes";
import CourseRoutes from "./Routes/Admin/CourseRoutes";
import ReportRoutes from "./Routes/Admin/ReportRoutes";
import StudentCreationRoutes from "./Routes/Admin/StudentCreationRoutes";
import ManageCampus from "./Routes/Admin/ManageCampus.jsx";
import ManageClusterRoutes from "./Routes/Admin/ManageClusterRoutes.jsx";

const App = () => {
  return (
    <div className="App w-full">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Router>
        <Routes>
          {publicRoutes}
          {authRoutes}
        </Routes>
        <ProtectedRoutes />
      </Router>
    </div>
  );
};

const ProtectedRoutes = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(window.location.pathname);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        user.getIdToken().then((token) => {
          localStorage.setItem("idtoken", token);
        });
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  let userRole = null;
  let flogin = null;
  try {
    userRole = user?.reloadUserInfo?.customAttributes
      ? JSON.parse(user?.reloadUserInfo?.customAttributes)?.role
      : null;
    flogin = user?.reloadUserInfo?.customAttributes ? JSON.parse(user?.reloadUserInfo?.customAttributes)?.flogin : null;
  } catch (error) {
    console.error("Failed to parse user role:", error);
  }
  const email = JSON.parse(localStorage.getItem("user"))?.email;
  const { data: admin } = useGetAdminUsingEmailQuery({
    variables: { email: email },
    skip: userRole != "bt-admin",
  });

  const { data: student, loading: studentLoading } =
    useGetStudentByEmailIdQuery({
      variables: { email },
      skip: userRole != "student",
    });

  const studentDetails = student?.getStudentByEmailId;

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-50">
        <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-primary"></div>
      </div>
    );
  }

  if (
    !user &&
    !["/login", "/forgetPassword", "/terms", "/contactus", "/privacy"].includes(currentPage)
  ) {
    return <Navigate to="/login" replace />;
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          userRole === "student"
            ? user && (
                <Navigate
                  to={
                    studentDetails?.clusterIds?.length === 1
                      ? `/student/courses/${studentDetails?.clusterIds[0]}`
                      : "/student/courses"
                  }
                />
              )
            : userRole === "staff"
            ? user && <Navigate to="/admin" />
            : user && <Navigate to="/admin/courses" />
        }
      />

      <Route
        path="/assignments"
        element={user ? <Assignments /> : <Navigate to="/login" />}
      />

      {(userRole === "bt-admin" || userRole === "student") && studentRoutes}
      {(userRole === "bt-admin" || userRole === "staff") && adminRoutes}
      {userRole === "bt-admin" &&
        admin?.getAdminUsingEmail?.isSuperAdmin &&
        CourseRoutes}
      {userRole === "bt-admin" &&
        admin?.getAdminUsingEmail?.isSuperAdmin &&
        AssignmentRoutes}
      {userRole === "bt-admin" &&
        admin?.getAdminUsingEmail?.isSuperAdmin &&
        ReportRoutes}
      {userRole === "bt-admin" &&
        admin?.getAdminUsingEmail?.isSuperAdmin &&
        StudentCreationRoutes}
      {userRole === "bt-admin" &&
        admin?.getAdminUsingEmail?.isSuperAdmin &&
        ManageCampus}
      {userRole === "bt-admin" &&
        admin?.getAdminUsingEmail?.isSuperAdmin &&
        ManageClusterRoutes}
      {userRole === "bt-admin" &&
        admin?.getAdminUsingEmail?.isSuperAdmin &&
        SuperAdminRoutes}

      {userRole === "bt-admin" &&
        admin?.getAdminUsingEmail?.permissions.courses?.length > 0 &&
        CourseRoutes}
      {userRole === "bt-admin" &&
        admin?.getAdminUsingEmail?.permissions.assignments?.length > 0 &&
        AssignmentRoutes}
      {userRole === "bt-admin" &&
        admin?.getAdminUsingEmail?.permissions.reports &&
        ReportRoutes}

      {userRole === "bt-admin" &&
        admin?.getAdminUsingEmail?.permissions.student_creation &&
        StudentCreationRoutes}

      {userRole === "staff" && (
        <Route key="admin" path="/admin" element={<StaffDashboard />} />
      )}
      {userRole === "bt-admin" && (
        <Route key="admin" path="/admin" element={<Admin />} />
      )}
      {userRole === "student" && flogin && (
        <Route
          key="student-onboarding"
          path="student/student-onboarding/:id"
          element={<StudentOnboarding />}
        />
      )}
      {userRole === "staff" && flogin && (
        <Route
          key="update-details"
          path="admin/staff-onboarding/:id"
          element={<StaffOnboarding />}
        />
      )}
    </Routes>
  );
};

export default App;
