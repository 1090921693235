import React, { useEffect, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";
import AdminLayout from "../../../Layout/index.jsx";
import {
  Button,
  Checkbox,
  Label,
  Select,
  TextInput,
  Textarea,
  Tooltip,
} from "flowbite-react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Editor } from "@tinymce/tinymce-react";
import { IoMdAdd } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { WithContext as ReactTags } from "react-tag-input";
import { v4 as uuidv4 } from "uuid";
import { FaCircleInfo } from "react-icons/fa6";
import {
  cppTooltipContent,
  cTooltipContent,
  javaTooltipContent,
  pythonTooltipContent,
} from "../../../Helpers/TooltipData.jsx";
import {
  useCreateQuestionMutation,
  useGetCourseQuery,
} from "../../../../../graphql/generated.tsx";
import ConformationModal from "../../../Components/ConformationModal.jsx";
import { useBeforeUnload } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useBlocker } from "../../../Helpers/Blocker.jsx";

const CreateQuestion = () => {
 const navigate = useNavigate();
  const [text, setText] = useState("");
  const [testCases, setTestCases] = useState([]);
  const [hints, setHints] = useState([""]);
  const [isValid, setIsValid] = useState(false);
  const [courseData, setCourseData] = useState(null);
  const [category, setCategory] = useState([]);
  const [title, setTitle] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [tags, setTags] = useState([]);
  const [companyTags, setCompanyTags] = useState([]);
  const [usePredefinedCode, setUsePredefinedCode] = useState(false);
  const [predefinedCode, setPredefinedCode] = useState({
    python: "",
    c: "",
    java: "",
    cpp: "",
  });
  const [showFunctionOnly, setShowFunctionOnly] = useState(false);
  const [targetFunctionName, setTargetFunctionName] = useState("");
  const [openModal, setOpenModal] = useState(false); // Manage modal state

  const handleCompanyTagDelete = (i) => {
    const newCompanyTags = [...companyTags];
    newCompanyTags.splice(i, 1);
    setCompanyTags(newCompanyTags);
  };

  const handleCompanyTagAddition = (tag) => {
    setCompanyTags([...companyTags, tag]);
  };

  const { id } = useParams();
  const editorConfig = {
    height: 300,
    menubar: false,
    plugins: [
      "advlist",
      "autolink",
      "lists",
      "link",
      "image",
      "charmap",
      "preview",
      "anchor",
      "searchreplace",
      "visualblocks",
      "code",
      "fullscreen",
      "insertdatetime",
      "media",
      "table",
      "code",
      "help",
      "wordcount",
    ],
    directionality: "ltr",
    toolbar:
      "undo redo | blocks | " +
      "bold italic forecolor | alignleft aligncenter " +
      "alignright alignjustify | bullist numlist outdent indent | " +
      "removeformat | help",
    content_style:
      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
    branding: false, // Remove the TinyMCE branding watermark
  };

  // Function to validate the form
  const validateForm = () => {
    const isTextValid = text?.trim() !== "";
    const areTestCasesValid = testCases.every(
      (testCase) => testCase.visibility && testCase.input && testCase.output
    );

    setIsValid(isTextValid && areTestCasesValid);
  };

  const handleAddTestCase = () => {
    const newTestCase = {
      id: uuidv4(),
      visibility: "SAMPLE",
      input: "",
      output: "",
      weightage: 0,
    };
    setTestCases([...testCases, newTestCase]);
  };

  const handleRemoveTestCase = (id) => {
    const updatedTestCases = testCases.filter((testCase) => testCase.id !== id);
    setTestCases(updatedTestCases);
  };

  const handleTestCaseChange = (id, field, value) => {
    const updatedTestCases = testCases.map((testCase) =>
      testCase.id === id ? { ...testCase, [field]: value } : testCase
    );
    setTestCases(updatedTestCases);
    validateForm(); // Validate form after each test case change
  };

  const handleAddHint = () => {
    setHints([...hints, ""]);
  };

  const handleRemoveHint = (index) => {
    const updatedHints = hints.filter((_, i) => i !== index);
    setHints(updatedHints);
  };

  const handleHintChange = (index, value) => {
    const updatedHints = [...hints];
    updatedHints[index] = value;
    setHints(updatedHints);
    validateForm();
  };
  const handleDelete = (i) => {
    const newTags = tags.slice(0);
    newTags.splice(i, 1);
    setTags(newTags);
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  const [createQuestion] = useCreateQuestionMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    if (!title.trim()) {
      toast.error("Title is required");
      return;
    }

    if (!text.trim()) {
      toast.error("Question text is required");
      return;
    }

    if (!category.id) {
      toast.error("Category is required");
      return;
    }

    if (!difficulty) {
      toast.error("Difficulty level is required");
      return;
    }

    if (testCases.length === 0) {
      toast.error("Test case is required");
      return;
    }

    for (let testCase of testCases) {
      if (!testCase.input.trim() || !testCase.output.trim()) {
        toast.error("Test case input and output cannot be empty");
        return;
      }
    }

    const totalMarks = testCases.reduce(
      (total, test) => total + parseFloat(test.weightage),
      0
    );

    if (difficulty === "EASY" && (totalMarks < 30 || totalMarks > 60)) {
      toast.error("Invalid Total Mark");
      return;
    }
    if (difficulty === "MEDIUM" && (totalMarks < 50 || totalMarks > 100)) {
      toast.error("Invalid Total Mark");
      return;
    }
    if (difficulty === "HARD" && (totalMarks < 70 || totalMarks > 150)) {
      toast.error("Invalid Total Mark");
      return;
    }

    if (usePredefinedCode) {
      if (
        predefinedCodeLanguages.includes("PYTHON") &&
        !predefinedCode.python.trim()
      ) {
        toast.error("Predefined Python code cannot be empty");
        return;
      }
      if (predefinedCodeLanguages.includes("C") && !predefinedCode.c.trim()) {
        toast.error("Predefined C code cannot be empty");
        return;
      }
      if (
        predefinedCodeLanguages.includes("JAVA") &&
        !predefinedCode.java.trim()
      ) {
        toast.error("Predefined JAVA code cannot be empty");
        return;
      }
    }

    const requestBody = {
      course_id: id,
      title,
      text,
      category,
      test_cases: testCases.map((test) => {
        return {
          id: test.id,
          input: test.input,
          output: test.output,
          visibility: test.visibility,
          weightage: parseFloat(test.weightage),
        };
      }),
      marks: totalMarks.toString(),
      hints,
      difficulty,
      tags: tags.map((tag) => tag.text),
      company_tags: companyTags.map((tag) => tag.text),
      predefinedCode: usePredefinedCode ? predefinedCode : null,
      showFunctionOnly,
      targetFunctionName,
    };

    try {
      await createQuestion({ variables: { createQuestionInput: requestBody } });
      toast.success("Question created successfully");
      window.history.back();
    } catch (error) {
      console.log(error);
      const errorMessage = error.message || "Error In Creating Question";
      toast.error(`Error: ${errorMessage}`);
    }
  };

  const { data } = useGetCourseQuery({ variables: { id } });
  useEffect(() => {
    setCourseData(data?.getCourse);
  }, [data]);
  const predefinedCodeLanguages = courseData?.languages || [];

  const hasUnsavedChanges = () =>
    text || testCases.length || title || category.id;

  useBlocker((blocked) => blocked, hasUnsavedChanges());

  useBeforeUnload((event) => {
    if (hasUnsavedChanges()) {
      event.preventDefault();
      event.returnValue = ""; 
    }
  });

  const handleBackClick = () => {
    if (hasUnsavedChanges()) {
      setOpenModal(true);
    } else {
      navigate(-1); 
    }
  };
  return (
    <AdminLayout>
      <div className="py-8 px-10 mx-auto max-md:px-5">
        <div className="flex items-center mb-6">
          <button
            onClick={handleBackClick} 
            className="text-gray-500 hover:text-gray-700 focus:outline-none w-[100px]"
          >
            <span className="gap-2 text-sm font-medium flex items-center">
              <IoMdArrowRoundBack className="h-6 w-6" />
              Go Back
            </span>
          </button>
        </div>
        <h2 className="text-3xl font-bold text-center w-full mb-4 max-md:text-xl">
          Create Question
        </h2>
        <form
          onSubmit={handleSubmit}
          className="max-w-2xl mx-auto bg-white rounded-md shadow-md p-8 max-md:p-6"
        >
          <div className="mb-5">
            <div className="block">
              <Label htmlFor="title" value="Title" className="font-semibold" />
            </div>
            <TextInput
              required
              id="title"
              type="text"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                validateForm();
              }}
            />
          </div>

          <div className="mb-5">
            <div className=" block">
              <Label
                htmlFor="questionText"
                value="Question Text"
                className="font-semibold"
              />
            </div>
            <Editor
              apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
              initialValue={""}
              init={editorConfig}
              onEditorChange={(content) => setText(content)}
            />
          </div>
          <div className="mb-5">
            <div className="block">
              <Label
                htmlFor="difficulty"
                value="Difficulty Level"
                className="font-semibold"
              />
            </div>
            <Select
              value={difficulty}
              onChange={(e) => setDifficulty(e.target.value)}
              required
            >
              <option value="" disabled>
                Select Difficulty
              </option>
              <option value="EASY">Easy</option>
              <option value="MEDIUM">Medium</option>
              <option value="HARD">Hard</option>
            </Select>
          </div>
          <div className="mb-5">
            <div className="block">
              <Label
                htmlFor="category"
                value="Category"
                className="font-semibold"
              />
            </div>
            <Select
              id="category"
              value={category.name}
              onChange={(e) => {
                const selectedCategory = courseData?.categories.find(
                  (cat) => cat.name === e.target.value
                );
                setCategory({
                  name: e.target.value,
                  id: selectedCategory ? selectedCategory.id : "",
                });
              }}
              required
              className=""
            >
              <option value="">Select Category</option>
              {courseData?.categories.map((cat) => (
                <option key={cat._id} value={cat.name}>
                  {cat.name}
                </option>
              ))}
            </Select>
          </div>

          <div className="block">
            <Label
              htmlFor="testCases"
              value="Test Cases"
              className="font-semibold mb-2"
            />
          </div>
          {testCases.map((testCase) => (
            <div key={testCase.id} className="mb-4 border rounded-md p-6 ">
              <Select
                value={testCase.visibility}
                onChange={(e) =>
                  handleTestCaseChange(
                    testCase.id,
                    "visibility",
                    e.target.value
                  )
                }
                required
                className="mb-3"
              >
                <option value="SAMPLE">Sample</option>
                <option value="HIDDEN">Hidden</option>
              </Select>
              <Textarea
                required
                type="text"
                className="mb-3"
                placeholder="Input"
                value={testCase.input}
                onChange={(e) =>
                  handleTestCaseChange(testCase.id, "input", e.target.value)
                }
              />
              <Textarea
                required
                type="text"
                className="mb-3"
                placeholder="Output"
                value={testCase.output}
                onChange={(e) =>
                  handleTestCaseChange(testCase.id, "output", e.target.value)
                }
              />
              <TextInput
                required
                type="number"
                className="mb-3"
                placeholder="Weightage"
                value={testCase.weightage}
                onChange={(e) =>
                  handleTestCaseChange(testCase.id, "weightage", e.target.value)
                }
              />
              <MdDelete
                className="h-6 w-8 m-2 cursor-pointer text-gray-500 hover:text-red-500"
                onClick={() => handleRemoveTestCase(testCase.id)}
              />
            </div>
          ))}

          <Button
            className="h-9 mb-5 flex items-center bg-primary"
            onClick={handleAddTestCase}
          >
            <IoMdAdd className="p-0 mr-1 mt-1" /> <span>Add testcase</span>
          </Button>

          <div className="block mb-2">
            <Label htmlFor="hints" value="Hints" className="font-semibold" />
          </div>
          {hints.map((hint, index) => (
            <div key={index} className="flex">
              <TextInput
                type="text"
                className="w-full mb-5"
                placeholder={`Hint ${index + 1}`}
                value={hint}
                onChange={(e) => handleHintChange(index, e.target.value)}
              />
              <MdDelete
                className="h-6 w-8 m-2 cursor-pointer text-gray-500 hover:text-red-500"
                onClick={() => handleRemoveHint(index)}
              />
            </div>
          ))}
          <Button
            className="h-9 mb-5 flex items-center bg-primary"
            onClick={handleAddHint}
          >
            <IoMdAdd className="mr-1 mt-1" /> <span>Add hint</span>
          </Button>

          <div className="block">
            <Label htmlFor="marks" value="Marks" className="font-semibold" />
          </div>
          <TextInput
            required
            type="number"
            className="mb-5"
            disabled
            value={testCases.reduce(
              (total, test) => total + parseFloat(test.weightage),
              0
            )}
          />
          <div className="mb-5">
            <div className="block">
              <Label htmlFor="tags" value="Tags" className="font-semibold" />
            </div>
            <ReactTags
              inline
              tags={tags}
              delimiters={delimiters}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              autocomplete
              classNames={{
                root: "flex flex-wrap",
                rootFocused: "border-blue-500",
                selected: "text-black rounded-full mt-2",
                tag: "bg-primary text-white rounded-xl py-1 px-2 cursor-pointer m-1",
                tagInput: " p-2 rounded", 
                inputField: "p-2 rounded bg-blue-100",
                suggestions: "mt-1 p-1 bg-white border border-gray-300 rounded",
                suggestionActive: "bg-gray-100",
                remove: "text-red-500 ml-2 font-bold",
              }}
            />
          </div>

          <div className="mb-5 w-full">
            <div className="block">
              <Label
                htmlFor="companyTags"
                value="Company Tags"
                className="font-semibold"
              />
            </div>
            <ReactTags
              inline
              tags={companyTags}
              delimiters={delimiters}
              handleDelete={handleCompanyTagDelete}
              handleAddition={handleCompanyTagAddition}
              autocomplete
              classNames={{
                root: "flex flex-wrap",
                rootFocused: "border-blue-500",
                selected: "text-gray-500 rounded-full mt-2",
                tag: "bg-primary text-gray-200 rounded-xl py-1 px-2 cursor-pointer m-1",
                tagInput: "w-full p-2 rounded",
                inputField: "w-full p-2 rounded",
                suggestions: "mt-1 p-1 bg-white border border-gray-300 rounded",
                suggestionActive: "bg-gray-100",
                remove: "text-red-500 ml-2 font-bold",
                placeholder: "Enter the tags",
              }}
            />
          </div>

          <div className="mb-5">
            <Checkbox
              id="usePredefinedCode"
              checked={usePredefinedCode}
              onChange={(e) => setUsePredefinedCode(e.target.checked)}
            />
            <Label
              htmlFor="usePredefinedCode"
              value="Use Predefined Code"
              className="ml-4"
            />
          </div>
          {usePredefinedCode && (
            <>
              {predefinedCodeLanguages.includes("PYTHON") && (
                <div className="mb-5">
                  <div className="flex items-center">
                    <Label
                      htmlFor="predefinedCodePython"
                      value="Predefined Code (Python)"
                      className="font-semibold"
                    />
                    <Tooltip
                      content={
                        <div
                          dangerouslySetInnerHTML={{
                            __html: pythonTooltipContent,
                          }}
                        />
                      }
                      className="h-[150px]"
                      placement="right"
                      style="light"
                    >
                      <FaCircleInfo className="ml-2" color="gray" />
                    </Tooltip>
                  </div>
                  <Textarea
                    id="predefinedCodePython"
                    value={predefinedCode.python}
                    onChange={(e) =>
                      setPredefinedCode({
                        ...predefinedCode,
                        python: e.target.value,
                      })
                    }
                    placeholder="Add predefined code for Python..."
                  />
                </div>
              )}

              {predefinedCodeLanguages.includes("C") && (
                <div className="mb-5">
                  <div className="flex items-center">
                    <Label
                      htmlFor="predefinedCodeC"
                      value="Predefined Code (C)"
                      className="font-semibold"
                    />
                    <Tooltip
                      content={
                        <div
                          dangerouslySetInnerHTML={{
                            __html: cTooltipContent,
                          }}
                        />
                      }
                      className="h-[150px]"
                      placement="right"
                      style="light"
                    >
                      <FaCircleInfo className="ml-2" color="gray" />
                    </Tooltip>
                  </div>
                  <Textarea
                    id="predefinedCodeC"
                    value={predefinedCode.c}
                    onChange={(e) =>
                      setPredefinedCode({
                        ...predefinedCode,
                        c: e.target.value,
                      })
                    }
                    placeholder="Add predefined code for C..."
                  />
                </div>
              )}

              {predefinedCodeLanguages.includes("JAVA") && (
                <div className="mb-5">
                  <div className="flex items-center">
                    <Label
                      htmlFor="predefinedCodeJava"
                      value="Predefined Code (Java)"
                      className="font-semibold"
                    />
                    <Tooltip
                      content={
                        <div
                          dangerouslySetInnerHTML={{
                            __html: javaTooltipContent,
                          }}
                        />
                      }
                      className="h-[150px]"
                      placement="right"
                      style="light"
                    >
                      <FaCircleInfo className="ml-2" color="gray" />
                    </Tooltip>
                  </div>
                  <Textarea
                    id="predefinedCodeJava"
                    value={predefinedCode.java}
                    onChange={(e) =>
                      setPredefinedCode({
                        ...predefinedCode,
                        java: e.target.value,
                      })
                    }
                    placeholder="Add predefined code for Java..."
                  />
                </div>
              )}

              {predefinedCodeLanguages.includes("CPP") && (
                <div className="mb-5">
                  <div className="flex items-center">
                    <Label
                      htmlFor="predefinedCodeCPP"
                      value="Predefined Code (C++)"
                      className="font-semibold"
                    />
                    <Tooltip
                      content={
                        <div
                          dangerouslySetInnerHTML={{
                            __html: cppTooltipContent,
                          }}
                        />
                      }
                      className="h-[150px]"
                      placement="right"
                      style="light"
                    >
                      <FaCircleInfo className="ml-2" color="gray" />
                    </Tooltip>
                  </div>
                  <Textarea
                    id="predefinedCodeCPP"
                    value={predefinedCode.cpp}
                    onChange={(e) =>
                      setPredefinedCode({
                        ...predefinedCode,
                        cpp: e.target.value,
                      })
                    }
                    placeholder="Add predefined code for C++..."
                  />
                </div>
              )}

              <div className="mb-5">
                <Checkbox
                  id="showFunctionOnly"
                  checked={showFunctionOnly}
                  onChange={(e) => setShowFunctionOnly(e.target.checked)}
                />
                <Label
                  htmlFor="showFunctionOnly"
                  value="Show Function Only"
                  className="ml-4"
                />
              </div>
              {showFunctionOnly && (
                <div className="mb-5">
                  <Label
                    htmlFor="targetFunctionName"
                    value="Target Function Name"
                    className="font-semibold"
                  />
                  <TextInput
                    id="targetFunctionName"
                    value={targetFunctionName}
                    onChange={(e) => setTargetFunctionName(e.target.value)}
                    placeholder="Enter the target function name..."
                  />
                </div>
              )}
            </>
          )}

          <Button type="submit" className="p-0 self-center bg-primary">
            Create
          </Button>
        </form>
      </div>
      {/* Confirmation Modal */}
      <ConformationModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        event={() => window.history.back()} // Navigate back if confirmed
        text="You have unsaved changes. Are you sure you want to leave without saving?"
      />
    </AdminLayout>
  );
};

export default CreateQuestion;
