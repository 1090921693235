import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import PracticeLayout from "../Layout";
import Compiler from "../Components/Compiler";
import CompilerQuestionCard from "../Components/CompilerQuestionCard";
import BackButton from "../Components/BackButton";
import CustomInput from "../Components/CustomInput";
import RunModel from "../Components/RunModel";
import SumbitModel from "../Components/SumbitModel";
import ErrorModel from "../Components/ErrorModel";
import {
  useCompileCodeLazyQuery,
  useGetCampusQuery,
  useGetCourseQuery,
  useGetLastFiveSubmissionsQuery,
  useGetQuestionQuery,
  useGetStudentByEmailIdQuery,
  useSubmitCodeMutation,
} from "../../../graphql/generated.tsx";
import "codemirror/mode/clike/clike";
import "codemirror/mode/python/python";

function CompilerScreen() {
  const [code, setCode] = useState("");
  const [language, setLanguage] = useState("");
  const [output, setOutput] = useState("");
  const [error, setError] = useState("");
  const [input, setInput] = useState("");
  const [question, setQuestion] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const errorRef = useRef(null);
  const [testResults, setTestResults] = useState([]);
  const [run, setRun] = useState(false);
  const [submit, setSubmit] = useState(false);
  const tableRef = useRef(null);
  const { questionId, courseId, clusterid } = useParams();
  const [isFullScreen, setFullScreen] = useState(false);
  const [codeApplied, setCodeApplied] = useState(false);
  const [initialCode, setInitialCode] = useState(""); // Store initial code
  const [isCodeChanged, setIsCodeChanged] = useState(false);
  const [latestSubmissions, setLatestSubmissions] = useState([]);
  const [autosavedCode, setAutoSavedCode] = useState(null);


  const { data, loading } = useGetQuestionQuery({
    variables: { id: questionId },
  });
  const { data: CourseData } = useGetCourseQuery({
    variables: { id: courseId },
  });

  const extractFunctionCode = (code, language, targetFunctionName) => {
    let functionRegex;
    language = language?.toLowerCase() || "";
    switch (language) {
      case "python":
        functionRegex = new RegExp(
          `def\\s+${targetFunctionName}\\s*\\([^)]*\\)\\s*:[\\s\\S]*?(?=\\n\\S|$)`,
          "g"
        );
        break;
      case "java":
        functionRegex = new RegExp(
          `class\\s+Solution\\s*{([\\s\\S]*?)}(?![^}]*})`,
          "g"
        );
        break;
      case "cpp":
      case "c":
        functionRegex = new RegExp(
          `\\w+(\\s*\\*\\s*|\\s+)${targetFunctionName}\\s*\\([^)]*\\)\\s*{([\\s\\S]*?)}(?![^}]*})`,
          "g"
        );
        break;
      default:
        return "";
    }

    const match = code?.match(functionRegex);
    return match ? match[0] : "";
  };
  const email = JSON.parse(localStorage.getItem("user"))?.email;
  const { data: studentData } = useGetStudentByEmailIdQuery({
    variables: { email },
  });
  const student = studentData?.getStudentByEmailId;

  const { data: campusData } = useGetCampusQuery({
    variables: { id: student?.campusId },
    skip: !student,
  });
  const campusDetails = campusData?.campus;
  const submitInput = {
    campus_name: campusDetails?.name,
    cluster_id: clusterid,
    student_id: student?.id,
    course_id: courseId,
    question_id: questionId,
  };

  const { data: submissionRecord, refetch } = useGetLastFiveSubmissionsQuery({
    variables: { submitInput },
    skip: !submitInput,
  });

  const autosave = submissionRecord?.getLastFiveSubmissions?.autosave;

  useEffect(() => {
    if (submissionRecord?.getLastFiveSubmissions?.autosave?.language) {
      const lang = autosave?.language;
      setLanguage(lang);
    } else if (CourseData?.getCourse) {
      const defaultLang = CourseData?.getCourse.languages[0];
      setLanguage(defaultLang);
    }
  }, [CourseData, autosave, submissionRecord]);

  useEffect(() => {
    if (data && !codeApplied) {
      setQuestion(data.getQuestion);
    }
  }, [data]);

  const setDefaultCodeBasedOnLanguage = (langKey) => {
    const latestCode = selectEitherAutoSavedOrSubmittedCode(
      autosavedCode,
      latestSubmissions
    );

    const lang = latestCode?.language;
    if (latestCode && langKey && lang && langKey === lang) {
      if (data?.getQuestion?.showFunctionOnly) {
        const functionCode = extractFunctionCode(
          latestCode.code,
          lang,
          data.getQuestion.targetFunctionName
        );
        setCode(functionCode);
        setCode(functionCode);
      } else {
        setCode(latestCode.code);
        setInitialCode(latestCode.code);
      }
      return;
    }

    const language = langKey?.toLowerCase();

    if (
      data?.getQuestion.showFunctionOnly &&
      data?.getQuestion.predefinedCode[language]
    ) {
      const functionCode = extractFunctionCode(
        data.getQuestion.predefinedCode[language],
        language,
        data.getQuestion.targetFunctionName
      );
      setCode(functionCode);
      setInitialCode(functionCode);
    } else if (data?.getQuestion?.predefinedCode?.[language]) {
      setCode(data?.getQuestion?.predefinedCode[language]);
      setInitialCode(data.getQuestion.predefinedCode[language]);
    } else {
      const defaultTemplate =
        language === "python"
          ? "#Type your code here"
          : "//Type your code here";
      setCode(defaultTemplate);
      setInitialCode(defaultTemplate);
    }
  };

  const selectEitherAutoSavedOrSubmittedCode = (
    autosavedCode,
    latestSubmissions
  ) => {
    const validAutoSave = autosavedCode?.code && autosavedCode?.language;

    if (!latestSubmissions.length && !validAutoSave) return null;

    let latestObj = null;

    if (validAutoSave && latestSubmissions.length) {
      const savedTime = new Date(autosavedCode.submittedAt);
      const latestSubmissionTime = new Date(latestSubmissions[0].submittedAt);
      latestObj =
        savedTime > latestSubmissionTime ? autosavedCode : latestSubmissions[0];
    }

    if (!latestObj) {
      latestObj = validAutoSave ? autosavedCode : latestSubmissions[0];
    }
    return latestObj;
  };

  useEffect(() => {
    let updated = false;

    if (submissionRecord) {
      const temp = submissionRecord?.getLastFiveSubmissions?.autosave;
      const scopedAutosave = temp?.code && temp?.language ? temp : null;
      const scopedLastestSubmissions = [
        ...(submissionRecord?.getLastFiveSubmissions?.submissions.filter(
          (sub) => sub.language && sub.code
        ) || []),
      ].sort((a, b) => new Date(b.submittedAt) - new Date(a.submittedAt));

      if (scopedAutosave || scopedLastestSubmissions.length > 0) {
        setAutoSavedCode(scopedAutosave);
        setLatestSubmissions(scopedLastestSubmissions);
        updated = true;
        const latestCode = selectEitherAutoSavedOrSubmittedCode(
          scopedAutosave,
          scopedLastestSubmissions
        );

        if (latestCode) {
          if (data?.getQuestion.showFunctionOnly) {
            const functionCode = extractFunctionCode(
              latestCode.code,
              language,
              data.getQuestion.targetFunctionName
            );
            
              setCode(functionCode)
              setInitialCode(functionCode)
          } else {
            setCode(latestCode.code);
            setInitialCode(latestCode.code);
          }
          setLanguage(latestCode.language);
        }
      }
    }
    const defaultLanguage = CourseData?.getCourse.languages?.[0];
    if (!updated && defaultLanguage) {
      setLanguage(language || defaultLanguage);
      setDefaultCodeBasedOnLanguage(language || defaultLanguage);
    }
  }, [CourseData, submissionRecord]);

  useEffect(() => {
    setDefaultCodeBasedOnLanguage(language);
  }, [language, data]);

  // useEffect(() => {
  //   if (submissionRecord?.getLastFiveSubmissions?.submissions) {
  //     const submissions = submissionRecord?.getLastFiveSubmissions?.submissions;
  //     setSubmissions(submissions);
  //   }

  //   const autosave = submissionRecord?.getLastFiveSubmissions?.autosave;
  //   const lastestSubmission = [
  //     ...(submissionRecord?.getLastFiveSubmissions?.submissions || []),
  //   ].sort((a, b) => new Date(b.submittedAt) - new Date(a.submittedAt))[0];

  //   if (autosave && autosave.language === language && autosave.code?.trim()) {
  //     console.log("autosave", autosave);
  //     console.log("latestsubmission", lastestSubmission);

  //     if (
  //       new Date(autosave.submittedAt) >
  //       new Date(lastestSubmission?.submittedAt)
  //     ) {

  //       setLanguage(autosave.language)
  //     } else {
  //       setLanguage(lastestSubmission.language)
  //     }
  //     return;
  //   }

  //   if (submissionRecord?.getLastFiveSubmissions?.autosave?.language) {
  //     const lang = submissionRecord?.getLastFiveSubmissions?.autosave?.language;
  //     setLanguage(lang);
  //   } else if (submissionRecord?.getLastFiveSubmissions?.submissions) {
  //     const submissions = submissionRecord?.getLastFiveSubmissions?.submissions;
  //     if (submissions?.length > 0) {
  //       const sortedSubmissions = [...submissions].sort(
  //         (a, b) => new Date(b.submittedAt) - new Date(a.submittedAt)
  //       );
  //       const submissionLanguage = sortedSubmissions.find(
  //         (sub) => sub.language
  //       );
  //       if (submissionLanguage) {
  //         setLanguage(submissionLanguage.language);
  //         return;
  //       }
  //     }
  //   } else if (CourseData?.getCourse) {
  //     const defaultLang = CourseData?.getCourse.languages[0];

  //     setLanguage(defaultLang);
  //   }
  // }, [CourseData, submissionRecord]);

  // const [initialRender, setInitialRender] = useState(true);

  // useEffect(() => {
  //   if (!data || !language) return;
  //   setQuestion(data.getQuestion);
  //   const langKey = language.toLowerCase();
  //   const autosave = submissionRecord?.getLastFiveSubmissions?.autosave;
  //   const lastestSubmission = [
  //     ...(submissionRecord?.getLastFiveSubmissions?.submissions || []),
  //   ].sort((a, b) => new Date(b.submittedAt) - new Date(a.submittedAt))[0];

  //   if (autosave && autosave.language === language && autosave.code?.trim()) {
  //     console.log("autosave", autosave);
  //     console.log("latestsubmission", lastestSubmission);

  //     if (
  //       new Date(autosave.submittedAt) >
  //       new Date(lastestSubmission?.submittedAt)
  //     ) {
  //       setCode(autosave.code);
  //       setInitialCode(autosave.code);
  //     } else {
  //       setCode(lastestSubmission.code);
  //       setInitialCode(lastestSubmission.code);
  //     }
  //     return;
  //   } else if (initialRender) {
  //     const submissions = submissionRecord?.getLastFiveSubmissions?.submissions;
  //     if (submissions?.length > 0) {
  //       const sortedSubmissions = [...submissions].sort(
  //         (a, b) => new Date(b.submittedAt) - new Date(a.submittedAt)
  //       );
  //       const submissionWithCode = sortedSubmissions.find((sub) =>
  //         sub.code?.trim()
  //       );
  //       if (submissionWithCode) {
  //         if (data.getQuestion.showFunctionOnly) {
  //           const functionCode = extractFunctionCode(
  //             submissionWithCode.code,
  //             langKey,
  //             data.getQuestion.targetFunctionName
  //           );
  //           setCode(functionCode);
  //           setInitialCode(functionCode);
  //         } else {
  //           setCode(submissionWithCode.code);
  //           setInitialCode(submissionWithCode.code);
  //         }
  //         return;
  //       }
  //     }
  //   }

  //   if (
  //     data.getQuestion.showFunctionOnly &&
  //     data.getQuestion.predefinedCode[langKey]
  //   ) {
  //     const functionCode = extractFunctionCode(
  //       data.getQuestion.predefinedCode[langKey],
  //       langKey,
  //       data.getQuestion.targetFunctionName
  //     );
  //     setCode(functionCode);
  //     setInitialCode(functionCode);
  //   } else if (data.getQuestion?.predefinedCode?.[langKey]) {
  //     setCode(data.getQuestion.predefinedCode[langKey]);
  //     setInitialCode(data.getQuestion.predefinedCode[langKey]);
  //   } else {
  //     const defaultTemplate =
  //       langKey === "python" ? "#Type your code here" : "//Type your code here";
  //     setCode(defaultTemplate);
  //     setInitialCode(defaultTemplate);
  //   }

  //   setInitialRender(false);
  // }, [data, language, submissionRecord]);

  // useEffect(() => {

  //   if (data && !codeApplied) {
  //     setQuestion(data.getQuestion);
  //     const langKey = language?.toLowerCase();
  //     const autosave = submissionRecord?.getLastFiveSubmissions?.autosave;
  //     const lastestSubmission = [
  //       ...(submissionRecord?.getLastFiveSubmissions?.submissions || []),
  //     ].sort((a, b) => new Date(b.submittedAt) - new Date(a.submittedAt))[0];

  //     if (autosave && autosave.language === language && autosave.code?.trim()) {
  //       console.log("autosave", autosave);
  //       console.log("latestsubmission", lastestSubmission);

  //       if (
  //         new Date(autosave.submittedAt) >
  //         new Date(lastestSubmission?.submittedAt)
  //       ) {
  //         setCode(autosave.code);
  //         setInitialCode(autosave.code);
  //       } else {
  //         setCode(lastestSubmission.code);
  //         setInitialCode(lastestSubmission.code);
  //       }
  //       return;
  //     } else {
  //       let foundCode = false;
  //       if (submissionRecord?.getLastFiveSubmissions?.submissions) {
  //         const sortedSubmissions = [
  //           ...submissionRecord.getLastFiveSubmissions.submissions,
  //         ].sort((a, b) => new Date(b.submittedAt) - new Date(a.submittedAt));
  //         for (let i = 0; i < sortedSubmissions.length; i++) {
  //           const submission = sortedSubmissions[i];
  //           if (submission.language === language && submission.code) {
  //             setCode(submission.code);
  //             setInitialCode(submission.code);
  //             foundCode = true;
  //             break;
  //           }
  //         }

  //         const langKey = language?.toLowerCase()
  //           ? language?.toLowerCase()
  //           : "c";
  //         if (data?.getQuestion.showFunctionOnly) {
  //           const targetFunctionCode = extractFunctionCode(
  //             code,
  //             langKey,
  //             data.getQuestion.targetFunctionName
  //           );
  //           setCode(targetFunctionCode);
  //           setInitialCode(targetFunctionCode);
  //         }
  //         // else {
  //         //   setCode(code);
  //         //   setInitialCode(code);
  //         // }
  //       }
  //       if (!foundCode) {
  //         if (
  //           data?.getQuestion.showFunctionOnly &&
  //           data?.getQuestion.predefinedCode[langKey]
  //         ) {
  //           const targetFunctionCode = extractFunctionCode(
  //             data.getQuestion.predefinedCode[langKey],
  //             langKey,
  //             data.getQuestion.targetFunctionName
  //           );
  //           setCode(targetFunctionCode);
  //           setInitialCode(targetFunctionCode);
  //         } else if (data?.getQuestion?.predefinedCode?.[langKey]) {
  //           setCode(data?.getQuestion?.predefinedCode[langKey]);
  //           setInitialCode(data?.getQuestion?.predefinedCode[langKey]);
  //         } else {
  //           if (langKey === "python") {
  //             setCode("#Type your code here");
  //             setInitialCode("#Type your code here");
  //           } else {
  //             setCode("//Type your code here");
  //             setInitialCode("//Type your code here");
  //           }
  //         }
  //       }
  //     }
  //   }
  // }, [data, language, submissionRecord]);

  useEffect(() => {
    if (isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
    }
  }, [isFullScreen]);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [testResults]);

  useEffect(() => {
    if (error) {
      errorRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [error]);

  const [
    compileCode,
    { data: compileData, loading: compileLoading, error: compileerror },
  ] = useCompileCodeLazyQuery();

  const [submitCode, { data: submitData, loading: submitLoading }] =
    useSubmitCodeMutation();

  useEffect(() => {
    if (
      compileData &&
      compileData?.compileCode?.compilationOutput?.stderr === ""
    ) {
      setTestResults(compileData.compileCode.Results);
    } else {
      setTestResults([]);
    }
  }, [compileData]);

  useEffect(() => {
    if (submitData?.submitCode) {
      setOutput(submitData?.submitCode);
    }
  }, [submitData]);

  const handleCodeChange = (newValue) => {
    setCode(newValue);
  };
  
  useEffect(()=>{
    setIsCodeChanged(code !== initialCode);
  },[code, initialCode])

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setRun(false);
    setInput("");
  };

  const handleSubmit = async () => {
    setIsChecked(false);
    const result = await submitCode({
      variables: {
        compilerCodeInput: {
          code,
          testCases: question?.test_cases?.map((test) => ({
            id: test.id,
            input: test.input,
            output: test.output,
            visibility: test.visibility,
            weightage: parseFloat(test.weightage),
          })),
          language,
          questionid: question.id,
        },
        submitInput: submitInput,
      },
    });

    if (result.data) {
      refetch({ submitInput });
    }

    setSubmit(true);
    setRun(false);
    setInput("");
  };

  const handleRun = async () => {
    try {
      setSubmit(false);
      setError(false);

      let data;

      if (!isChecked) {
        const response = await compileCode({
          variables: {
            compileCodeInput: {
              code,
              testCases: question?.test_cases?.map((test) => ({
                id: test.id,
                input: test.input,
                output: test.output,
                visibility: test.visibility,
                weightage: parseFloat(test.weightage),
              })),
              language,
              questionid: question.id,
            },
          },
        });
        setRun(true);
        data = response.data;
      } else {
        const response = await compileCode({
          variables: {
            compileCodeInput: {
              code,
              testCases: [
                {
                  id: "0",
                  input: input,
                  output: " ",
                  visibility: "SAMPLE",
                  weightage: 0,
                },
              ],
              language,
              questionid: question?.id,
            },
          },
        });

        data = response.data;
      }

      if (data && data?.compileCode?.compilationOutput?.stderr === "") {
        setRun(true);
        setTestResults(data.compileCode.Results);
      }
    } catch (err) {
      setTestResults([]);

      if (err?.graphQLErrors && err?.graphQLErrors.length > 0) {
        const errorMessage = err?.graphQLErrors[0]?.message;
        setError(errorMessage);
      } else {
        setError(
          "There could some logic error in your code or network connectivity issue. Kindly try again later"
        );
      }
    }
  };
  const setToDefault = () => {
    const langKey = language?.toLowerCase();
    if (
      data?.getQuestion.showFunctionOnly &&
      data?.getQuestion.predefinedCode[langKey]
    ) {
      const targetFunctionCode = extractFunctionCode(
        data.getQuestion.predefinedCode[langKey],
        langKey,
        data.getQuestion.targetFunctionName
      );
      setCode(targetFunctionCode);
    } else if (data?.getQuestion.predefinedCode[langKey]) {
      setCode(data.getQuestion.predefinedCode[langKey]);
    }
  };
  const onApply = (code, lang) => {
    setCodeApplied(true);
    setLanguage(lang || "C");
    const langKey = lang?.toLowerCase() ? lang?.toLowerCase() : "c";
    if (data?.getQuestion.showFunctionOnly) {
      const targetFunctionCode = extractFunctionCode(
        code,
        langKey,
        data.getQuestion.targetFunctionName
      );
      setCode(targetFunctionCode);
      setInitialCode(targetFunctionCode);
    } else {
      setCode(code);
      setInitialCode(code);
    }
  };
  return (
    <PracticeLayout isFullScreen={isFullScreen}>
      <BackButton setFullScreen={setFullScreen} isFullScreen={isFullScreen} />
      <div className={!isFullScreen ? "" : "flex"}>
        <div className={!isFullScreen ? "mb-5" : "w-1/2 pr-4"}>
          <CompilerQuestionCard
            question={question}
            loading={loading}
            setLanguage={setLanguage}
            onApply={onApply}
            setInitialCode={setInitialCode}
            extractFunctionCode={extractFunctionCode}
            showFunctionOnly={data?.getQuestion?.showFunctionOnly}
            targetFunctionName={data?.getQuestion?.targetFunctionName}
            data={submissionRecord}
            submitInput={submitInput}
          />
        </div>
        <div className={!isFullScreen ? "" : "w-1/2 pl-4"}>
          <Compiler
            student_id={student?.id}
            setCodeApplied={setCodeApplied}
            question_id={question?.id}
            language={language}
            code={code}
            setToDefault={setToDefault}
            setLanguage={setLanguage}
            handleCodeChange={handleCodeChange}
            errorRef={errorRef}
            handleSubmit={handleSubmit}
            handleRun={handleRun}
            CourseData={CourseData?.getCourse}
            compileLoading={compileLoading}
            submitLoading={submitLoading}
            isChecked={isChecked}
            isCodeChanged={isCodeChanged}
            submitInput={submitInput}
            setInitialCode={setInitialCode}
          />
          <CustomInput
            isChecked={isChecked}
            handleCheckboxChange={handleCheckboxChange}
            setInput={setInput}
            input={input}
          />

          {!compileerror && run && testResults?.length != 0 && (
            <RunModel
              tableRef={tableRef}
              isChecked={isChecked}
              testResults={testResults}
              loading={compileLoading}
            />
          )}

          {output && submit && (
            <SumbitModel output={output} question={question} />
          )}

          {compileerror && !submit && (
            <ErrorModel error={compileerror} language={language} />
          )}
          {testResults.length === 0 &&
            !submit &&
            run &&
            !compileLoading &&
            !compileerror && (
              <ErrorModel
                error={compileData?.compileCode?.compilationOutput?.stderr}
                language={language}
                runtimeError={testResults.length === 0}
              />
            )}
        </div>
      </div>
    </PracticeLayout>
  );
}

export default CompilerScreen;
