import React, { useState, useEffect, useCallback } from "react";
import { auth } from "../../utils/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Label, Spinner, TextInput } from "flowbite-react";
import NavBar from "../../Common/NavBar";
import {
  useGetCampusQuery,
  useGetStaffByEmailIdLazyQuery,
  useGetStudentByEmailIdLazyQuery,
} from "../../graphql/generated.tsx";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";

function decrypt(text) {
  const secretKey = "btcampushub"; 
  const bytes = CryptoJS.AES.decrypt(text, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
}

const LoginForm = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [getStaffByEmailId, { data: StaffData }] =
    useGetStaffByEmailIdLazyQuery();
  const [getStudentByEmailId, { data: StudentData }] =
    useGetStudentByEmailIdLazyQuery();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const encryptedEmail = query.get("email");
    const encryptedPassword = query.get("password");

    // Decrypt the email and password if they are present
    if (encryptedEmail) {
      setEmail(decrypt(encryptedEmail));
    }
    if (encryptedPassword) {
      setPassword(decrypt(encryptedPassword));
    }
  }, [location.search]);

  const { data: campusDetails } = useGetCampusQuery({
    variables: {
      id: StudentData
        ? StudentData.getStudentByEmailId.campusId
        : StaffData
        ? StaffData.getStaffByEmailId.campusId
        : null,
    },
    skip: !StudentData && !StaffData,
  });

 const fetchData = async () => {
   try {
     setIsLoading(true);
     const userCredential = await signInWithEmailAndPassword(
       auth,
       email,
       password
     );
     const user = userCredential.user;
     localStorage.setItem("user", JSON.stringify(user));
     const idTokenResult = await user.getIdTokenResult();
     const { claims } = idTokenResult;
     
console.log("student data", StudentData);

     if (claims.role === "student" && StudentData) {
       localStorage.setItem(
         "studentData",
         JSON.stringify(StudentData.getStudentByEmailId)
       );
     } else if (claims.role === "staff" && StaffData) {
       localStorage.setItem(
         "staffData",
         JSON.stringify(StaffData.getStaffByEmailId)
       );
     }

     if (campusDetails) {
       localStorage.setItem(
         "campusDetails",
         JSON.stringify(campusDetails.campus)
       );
     }

     if (claims.flogin === false) {
       navigate("/");
     } else {
       if (claims.role === "staff" && StudentData?.getStudentByEmailId) {
         navigate(`/admin/staff-onboarding/${StaffData.getStaffByEmailId.id}`);
       } else if (claims.role === "student" && StudentData?.getStudentByEmailId) {
         navigate(
           `/student/student-onboarding/${StudentData.getStudentByEmailId.id}`
         );
       } else {
         navigate("/");
       }
     }
   } catch (error) {
    console.log("error", error);
    
     let errorMessage = "An unexpected error occurred.";

     switch (error.code) {
       case "auth/invalid-email":
         errorMessage = "Invalid email format.";
         break;
       case "auth/user-disabled":
         errorMessage = "This account has been disabled.";
         break;
       case "auth/user-not-found":
         errorMessage = "No user found with this email.";
         break;
       case "auth/wrong-password":
         errorMessage = "Incorrect password.";
         break;
       case "auth/too-many-requests":
         errorMessage = "Too many attempts. Try again later.";
         break;
       case "auth/invalid-credential":
         errorMessage = "Invalid email format or credentials.";
         break;
       case "auth/timeout":
         errorMessage = "The network operation timed out.";
         break;
       case "auth/captcha-check-failed":
         errorMessage = "The captcha check failed. Please try again.";
         break;
       case "auth/network-request-failed":
         errorMessage = "A network request failed.";
         break;
       default:
         console.error("Unexpected error:", error.code, error.message);
         errorMessage = "An unexpected error occurred. Please try again later.";
         break;
     }

     toast.error(errorMessage);
   } finally {
     setIsLoading(false);
   }
 };

  const submitLogin = (e) => {
    e.preventDefault();
    if (email && password) {
      fetchData();
    } else {
      toast.error("Please enter both email and password.");
    }
  };

  return (
    <>
      <NavBar isLogin />
      <div className="flex flex-col md:flex-row min-h-screen min-w-screen justify-between px-8 md:px-28 py-12 mx-auto bg-slate-100 items-center">
        <div className="hidden md:block w-1/2 pr-10">
          <img
            src="/developer-team.svg"
            alt="Your Company"
            className="w-full h-auto"
          />
        </div>

        <div className="bg-white w-full md:w-[400px] p-10 h-auto md:h-1/2 rounded-lg max-md:my-auto">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              className="mx-auto h-16 w-auto"
              src="/favicon.ico"
              alt="Your Company"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Sign in to your account
            </h2>
          </div>

          <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" onSubmit={submitLogin}>
              <div>
                <Label htmlFor="email" value="User email" />
                <div className="mt-2">
                  <TextInput
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <Label htmlFor="password" value="Password" />
                  <div className="text-sm">
                    <a
                      href="/forgetPassword"
                      className="font-semibold text-primary hover:text-indigo-500"
                    >
                      Forgot password?
                    </a>
                  </div>
                </div>
                <div className="mt-2">
                  <TextInput
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  disabled={isLoading}
                >
                  {isLoading && <Spinner size="sm" className="mr-2" />}
                  Sign in
                </button>
              </div>
            </form>
          </div>

          <div className="mt-6 text-center text-sm text-gray-600">
            <a
              href="/terms"
              className="font-semibold text-primary hover:text-indigo-500"
            >
              Terms & Conditions
            </a>{" "}
            |{" "}
            <a
              href="/privacy"
              className="font-semibold text-primary hover:text-indigo-500"
            >
              Privacy policy
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
