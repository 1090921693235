import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  Button,
} from "@mui/material";
import { Badge, Button as FlowbiteButton, Modal } from "flowbite-react";

import { useNavigate } from "react-router-dom";
import {
  useCreateRazorpayOrderMutation,
  useGetCampusQuery,
  useGetLastFiveSubmissionsLazyQuery,
  useGetStudentByEmailIdQuery,
  useSavePaymentDetailsMutation,
} from "../../../graphql/generated.tsx";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import CancelIcon from "@mui/icons-material/Cancel";
import { useRazorpay } from "react-razorpay";
import { toast } from "react-toastify";
import { handlePayment } from "../../../utils/paymentHelper.js";

const PAID_CLUSTER_ID = "e27a3599-01e6-4e13-a195-9c4f2a0a9abc";

const QuestionListingTable = ({
  data: questions,
  courseId,
  catName,
  clusterid,
  handlePrevPage,
  handleNextPage,
  currentPage,
  totalPages,
  categories,
  whyChoose,
  price,
  dis_price,
}) => {
  const email = JSON.parse(localStorage.getItem("user"))?.email;
  const navigate = useNavigate();
  const [submissions, setSubmissions] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const { error, isLoading, Razorpay } = useRazorpay();
  const [createRazorpayOrder] = useCreateRazorpayOrderMutation();
  const [savePaymentDetails] = useSavePaymentDetailsMutation();

  const handlePaymentSuccess = async (response) => {
    try {
      const result = await savePaymentDetails({
        variables: {
          createPaymentInput: {
            course_id: courseId,
            cluster_id: clusterid,
            order_id: response.razorpay_order_id,
            payment_id: response.razorpay_payment_id,
            student_id: studentData?.getStudentByEmailId.id,
            amount_paid: dis_price,
          },
        },
      });

      if (result?.data) {
        window.location.reload();
        console.log("Payment details saved successfully:", result.data);
        toast.success("Payment Successful!");
      } else {
        toast.error(
          "Payment details save incomplete:",
          result.errors || result
        );
      }
    } catch (error) {
      console.error("Error saving payment details:", error);
      alert(
        "Payment was successful, but we encountered an issue while saving your payment details. Please contact support if this persists."
      );
    }
  };

  const handlePaymentFailure = (error) => {
    toast.error("Payment Failed.");
    console.error("Payment Failure:", error);
  };

  const { data: studentData } = useGetStudentByEmailIdQuery({
    variables: { email },
  });

  const { data: campusData } = useGetCampusQuery({
    variables: { id: studentData?.getStudentByEmailId?.campusId },
    skip: !studentData,
  });

  const [getSubmissions] = useGetLastFiveSubmissionsLazyQuery();
  const purchasedCourses =
    studentData?.getStudentByEmailId?.purchasedCourses || [];
  const isCoursePurchased = purchasedCourses.some(
    (course) => course.course_id === courseId && course.cluster_id === clusterid
  );

  const campusName = isCoursePurchased
    ? "Better Tomorrow"
    : campusData?.campus.name;
  useEffect(() => {
    const fetchSubmissions = async () => {
      if (!studentData || !campusName || questions.length === 0) return;

      const submissionPromises = questions.map(async (question) => {
        const submitInput = {
          campus_name: campusName,
          cluster_id: clusterid,
          student_id: studentData?.getStudentByEmailId?.id,
          course_id: courseId,
          question_id: question.id,
        };

        try {
          const { data } = await getSubmissions({ variables: { submitInput } });
          return { questionId: question.id, data };
        } catch {
          return { questionId: question.id, data: null };
        }
      });

      const results = await Promise.all(submissionPromises);
      const submissionsMap = results.reduce((acc, { questionId, data }) => {
        acc[questionId] = data?.getLastFiveSubmissions || null;
        return acc;
      }, {});

      setSubmissions(submissionsMap);
    };

    if (isCoursePurchased) {
      fetchSubmissions();
    }
  }, [
    studentData,
    campusData,
    questions,
    courseId,
    clusterid,
    getSubmissions,
    isCoursePurchased,
  ]);

  const getStatus = (submission) => {
    if (!submission) return "Awaiting";
    if (submission.is_completed) return "Completed";
    if (submission.submissions?.length > 0) return "In Progress";
    return "Awaiting";
  };

  const getStatusIcon = (status) => {
    if (status === "Completed") return <CheckCircleIcon color="success" />;
    if (status === "In Progress")
      return <HourglassEmptyIcon className="text-primary" />;
    return "-";
  };

  function getDifficultyColor(difficulty) {
    switch (difficulty) {
      case "EASY":
        return "green";
      case "MEDIUM":
        return "#F5C650";
      case "HARD":
        return "red";
      default:
        return "gray";
    }
  }
  const isPaidCourse = clusterid === PAID_CLUSTER_ID;

  const handlePayNow = () => {
    handlePayment({
      courseId: courseId,
      createRazorpayOrder,
      Razorpay,
      onSuccess: handlePaymentSuccess,
      onFailure: handlePaymentFailure,
    });
    setOpenModal(false);
  };

  const handleQuestionClick = (questionId) => {
    if (!isCoursePurchased && isPaidCourse) {
      setOpenModal(true);
    } else {

      navigate(
        `/student/courses/question/${clusterid}/${courseId}/${questionId}`,
        {
          state: {
            fromPath: window.location.pathname,
            pageNumber: currentPage,
          }
        }
      );
    }
  };

  return (
    <div className="relative">
      <Modal show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header>
          <h2 className="text-lg font-bold text-gray-900">
            Unlock Your Potential with This Course
          </h2>
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            {/* Course Details */}
            <div>
              <h3 className="font-semibold text-lg text-gray-800">
                Why Choose This Course?
              </h3>
              <p className="text-gray-700">{whyChoose}</p>
            </div>

            {/* Payment Summary */}
            <div className="border-t border-gray-200 pt-4">
              <h3 className="text-lg font-bold text-gray-800 mb-3">Payment Summary</h3>
              <div className="flex justify-between items-center mb-2">
                <span className="text-sm text-gray-500">Price:</span>
                <span className="text-sm">₹{dis_price}</span>
              </div>
              <div className="flex justify-between items-center mb-2">
                <span className="text-sm text-gray-500">Tax (18%) :</span>
                <span className="text-sm">₹{dis_price * 0.18}</span>
              </div>
              <div className="flex justify-between items-center mt-4">
                <span className="text-lg font-bold text-gray-800">Total:</span>
                <span className="text-lg font-bold text-gray-800">
                ₹{(dis_price * 1.18).toFixed(2)}
                </span>
              </div>
          </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="flex flex-col items-center space-y-2">
          <FlowbiteButton
            className="bg-primary text-white w-full font-medium rounded-md hover:bg-primary-dark transition duration-200"
            onClick={handlePayNow}
          >
            Purchase Now 
          </FlowbiteButton>
          <p className="text-xs text-gray-500 mt-2 text-center">
            Start learning risk-free today!
          </p>
        </Modal.Footer>
      </Modal>

      <Card className="h-full w-full mt-5">
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#F3F4F6" }}>
              <TableCell
                sx={{ fontWeight: "bold", color: "black", fontSize: "16px" }}
              >
                Question Title
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: "bold", color: "black", fontSize: "16px" }}
              >
                Difficulty
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: "bold", color: "black", fontSize: "16px" }}
              >
                Attained Score
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: "bold", color: "black", fontSize: "16px" }}
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questions.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={4}
                  align="center"
                  className="text-gray-500"
                  sx={{ padding: "20px" }}
                >
                  No questions available
                </TableCell>
              </TableRow>
            ) : (
              questions.map((question) => {
const status = question.status
              const statusMap = {
                0: "Awaiting",
                1: "In Progress",
                2: "Completed",
              }
              const statusColor =
                status === 2
                  ? "#4CAF50"
                  : status === 1
                  ? "#FFC107"
                  : status === 0
                  ? "#A9A9A9"
                  : ""; 
                return (
                  <TableRow
                    key={question.id}
                    onClick={() => handleQuestionClick(question?.question_id)}
                    sx={{
                      "&:hover": { backgroundColor: "#F9FAFB" },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell sx={{ fontWeight: "medium", fontSize: "1rem" }}>
                      {question.title}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: getDifficultyColor(question?.difficulty),
                      }}
                    >
                      {question?.difficulty
                        ? question.difficulty.charAt(0).toUpperCase() +
                          question.difficulty.slice(1).toLowerCase()
                        : "Unknown"}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: "bold", fontSize: "1.1rem" }}
                    >
                      {question?.mark || "-"}
                    </TableCell>
                    <TableCell align="center" 
                                        sx={{ color: statusColor, fontWeight: "bold" }}

>
                    {statusMap[status]}
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
        <div className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Button
            variant="outlined"
            size="small"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default QuestionListingTable;
