import { Route } from "react-router-dom";
import Signup from "../Modules/Auth/Signup";
import LoginPage from "../Modules/Auth/Login";
import ForgetPassword from "../Modules/Auth/ForgetPassword";
import ResetPassword from "../Modules/Auth/ResetPassword";
import AuthActions from "../Modules/Auth/Components/auth-actions";
import AuthUserActions from "../Modules/Auth/authUserActions";

const authRoutes = [
  <Route key={'SignUpRoute'} path="/signUp" element={<Signup />} />,
  <Route key={'LoginRoute'} path="/login" element={<LoginPage />} />,
  <Route key={'ForgotPasswordRoute'} path="/forgetPassword" element={<ForgetPassword />} />,
  <Route key={'PasswordResetRoute'} path="/password-reset" element={<ResetPassword />} />,
  <Route key={'AuthActionRoute'}
    path="/auth/action"
    element={
      <AuthActions>
        <AuthUserActions />
      </AuthActions>
    }
  />,
];

export default authRoutes;
