import CourseCard from "./CourseCard.jsx";
import PaidCourseCard from "./PaidCourseCard.jsx";

const CoursesListing = ({
  courseData,
  clusterId,
  paid,
  student_id,
}) => {
  console.log({ courseData });
  return (
    <div className="flex gap-5 flex-wrap max-md:ml-5 mt-5 max-md:mt-2 md:mx-10 lg:mx-2">
      {!paid
        ? courseData?.map((course) => (
            <CourseCard
              key={course._id}
              course_id={course.id}
              name={course.name}
              short_description={course.short_description}
              time_period={course.time_period}
              level={course.level}
              clusterId={clusterId}
              paid={paid}
            />
          ))
        : courseData?.map((course) => (
            <PaidCourseCard
              key={course._id}
              course_id={course.id}
              name={course.name}
              short_description={course.short_description}
              time_period={course.time_period}
              price={course.price}
              clusterId={clusterId}
              student_id={student_id}
              level={course.level}
              categories={course.categories}
              whyChoose={course.whyChoose}
              benefits={course.benefits}
              companies={course.companies}
              dis_price={course.dis_price}
            />
          ))}
    </div>
  );
};

export default CoursesListing;
