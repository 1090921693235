import React from "react";
import StudentLayout from "../Layout";
import {
  useGetOrdersByStudentIdQuery,
  useGetStudentByEmailIdQuery,
} from "../../../graphql/generated.tsx";
import {
  FaBox,
  FaCalendarAlt,
  FaMoneyBillWave,
  FaIdBadge,
  FaShoppingCart,
} from "react-icons/fa";

const Orders = () => {
  const email = JSON.parse(localStorage.getItem("user")).email;
  const {
    data: StudentData,
    loading: studentLoading,
    error: studentError,
  } = useGetStudentByEmailIdQuery({
    variables: { email },
  });

  const {
    data,
    loading: ordersLoading,
    error: ordersError,
  } = useGetOrdersByStudentIdQuery({
    variables: { student_id: StudentData?.getStudentByEmailId?.id },
    skip: !StudentData?.getStudentByEmailId?.id,
  });

  if (studentLoading || ordersLoading) {
    return (
      <StudentLayout>
        <div className="flex items-center justify-center h-full">
          <p className="text-lg font-medium text-gray-700">Loading...</p>
        </div>
      </StudentLayout>
    );
  }

  if (studentError || ordersError) {
    return (
      <StudentLayout>
        <div className="flex items-center justify-center h-full">
          <p className="text-lg font-medium text-red-500">
            Error loading data. Please try again later.
          </p>
        </div>
      </StudentLayout>
    );
  }

  const orders = data?.getOrdersByStudentId || [];

  return (
    <StudentLayout>
      <div className="relative m-10 p-10 rounded-lg shadow-lg bg-gray-50 min-h-screen max-md:ml-12">
        <div className="flex items-center justify-center mb-10">
          <h1 className="text-5xl font-extrabold tracking-wide text-primary max-md:text-3xl">
            🛒 Your Orders
          </h1>
        </div>
        {orders.length > 0 ? (
          <div className="space-y-10 p-10">
            {orders.map((order, index) => (
              <div
                key={order.order_id}
                className="bg-white rounded-xl shadow-lg p-8 border-l-4 border-primary"
              >
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-2xl font-bold text-primary">
                    Invoice #{index + 1}
                  </h2>
                  <span className="text-gray-500 text-sm">
                    <FaCalendarAlt className="inline mr-1" />
                    {new Date(order.payment_date).toLocaleDateString()}
                  </span>
                </div>
                <hr className="mb-5 border-gray-300" />
                <div className="grid grid-cols-4 gap-5">
                  <div className="flex items-center">
                    <FaIdBadge className="text-primary mr-3" />
                    <div>
                      <span className="font-semibold block">Order ID</span>
                      <span className="text-gray-700">{order.order_id}</span>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <FaBox className="text-primary mr-3" />
                    <div>
                      <span className="font-semibold block">Course Name</span>
                      <span className="text-gray-700">{order.course_name}</span>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <FaMoneyBillWave className="text-primary mr-3" />
                    <div>
                      <span className="font-semibold block">Amount Paid</span>
                      <span className="text-green-500">₹{order.amount}</span>
                    </div>
                  </div>
                  {/* <div className="flex items-center">
                    <FaCalendarAlt className="text-primary mr-3" />
                    <div>
                      <span className="font-semibold block">
                        Date of Purchase
                      </span>
                      <span className="text-gray-700">
                        {new Date(order.payment_date).toLocaleDateString()}
                      </span>
                    </div>
                  </div> */}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-center text-gray-600 text-lg font-medium">
            No orders found.
          </p>
        )}
      </div>
    </StudentLayout>
  );
};

export default Orders;
