import React, { useEffect, useState } from "react";
import PracticeLayout from "../Layout";
import { useParams } from "react-router-dom";
import StudentCategoryCard from "../Components/StudentCategoryCard";
import {
  useCreateRazorpayOrderMutation,
  useGetCourseForStudentsQuery,
  useGetCourseQuery,
  useGetStudentByEmailIdQuery,
  useSavePaymentDetailsMutation,
} from "../../../graphql/generated.tsx";
import StudentLayout from "../../Student/Layout/index.jsx";
import { FaRegClock, FaSignal } from "react-icons/fa6";
import { RiQuestionAnswerFill } from "react-icons/ri";
import RecommendedCourseCards from "../Components/RecommendedCourseCards.jsx";
import { Modal, Button as FlowbiteButton } from "flowbite-react";
import { handlePayment } from "../../../utils/paymentHelper.js";
import { useRazorpay } from "react-razorpay";
import { toast } from "react-toastify";

const PAID_CLUSTER_ID = "e27a3599-01e6-4e13-a195-9c4f2a0a9abc";

const StudentCourseDetails = () => {
  const [courseData, setCourseData] = useState(null);
  const { courseId, clusterid } = useParams();
  const email = JSON.parse(localStorage.getItem("user"))?.email;
  const { data: courseDetailsWithQuestions, loading } =
    useGetCourseForStudentsQuery({ variables: { id: courseId } });

  const [isRecommendedEmpty, setIsRecommendedEmpty] = useState(false);
  const { data: studentData } = useGetStudentByEmailIdQuery({
    variables: { email },
  });

  const purchasedCourses =
    studentData?.getStudentByEmailId?.purchasedCourses || [];

  useEffect(() => {
    setCourseData(courseDetailsWithQuestions?.getCourseForStudents);
  }, [courseDetailsWithQuestions]);

  const isPaidCourse = clusterid === PAID_CLUSTER_ID;
  const isCoursePurchased = purchasedCourses.some(
    (course) => course.course_id === courseId && course.cluster_id === clusterid
  );

  const [savePaymentDetails] = useSavePaymentDetailsMutation();

  const handlePaymentSuccess = async (response) => {
    try {
      const result = await savePaymentDetails({
        variables: {
          createPaymentInput: {
            course_id: courseId,
            cluster_id: clusterid,
            order_id: response.razorpay_order_id,
            payment_id: response.razorpay_payment_id,
            student_id: studentData?.getStudentByEmailId.id,
            amount_paid: courseData?.dis_price,
          },
        },
      });

      if (result?.data) {
        window.location.reload();
        console.log("Payment details saved successfully:", result.data);
        toast.success("Payment Successful!");
      } else {
        toast.error(
          "Payment details save incomplete:",
          result.errors || result
        );
      }
    } catch (error) {
      console.error("Error saving payment details:", error);
      alert(
        "Payment was successful, but we encountered an issue while saving your payment details. Please contact support if this persists."
      );
    }
  };

  const handlePaymentFailure = (error) => {
    console.error("Payment Failure:", error);
    toast.error("Payment Failed.");
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [createRazorpayOrder] = useCreateRazorpayOrderMutation();
  const { error, isLoading, Razorpay } = useRazorpay();

  const handlePayNow = (e) => {
    e.stopPropagation();
    handlePayment({
      courseId: courseId,
      createRazorpayOrder,
      Razorpay,
      onSuccess: handlePaymentSuccess,
      onFailure: handlePaymentFailure,
    });
    setIsModalOpen(false);
  };

  useEffect(()=>{
    window.scrollTo(0,0);
  },[])

  if (loading) {
    return (
      <PracticeLayout>
        <div className="flex justify-center items-center h-screen bg-gray-50">
          <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-primary"></div>
        </div>
      </PracticeLayout>
    );
  }

  return (
    <StudentLayout>
      <div className="flex flex-wrap max-md:p-6 max-md:ml-2 lg:p-4 justify-center mx-auto gap-5 w-[100%]">
        {/* Main Content */}
        <div className="w-full md:w-[70%]">
          <div className="p-2">
            <div className="relative h-[480px] flex flex-col justify-center md:h-[400px]">
              <div className="p-6 lg:rounded-xl  bg-white w-full border border-black  md:h-[80%]">
                <div className="flex flex-col gap-4 md:flex-row md:items-center md:gap-6 justify-between">
                  <div className="w-full md:w-[60%]">
                    <h1 className="font-bold text-lg md:text-2xl mb-2 flex items-center gap-2">
                      {courseData?.name}
                      {isPaidCourse && !isCoursePurchased && (
                        <span className="bg-secondary text-white text-xs font-semibold px-2 py-1 rounded">
                          Paid Course
                        </span>
                      )}
                    </h1>
                    <p className="text-slate-500 text-sm md:text-base">
                      {courseData?.short_description}
                    </p>
                    {isPaidCourse && !isCoursePurchased && (
                      <button
                        onClick={handlePayNow}
                        className="flex-1 py-2 px-4 bg-primary text-white text-sm rounded-sm font-medium hover:bg-primary-dark transition duration-200 mt-4"
                        >
                        Purchase Now
                      </button>
                    )}
                  </div>
                  <div className="border border-black p-4 bg-white rounded-xl w-full md:w-auto">
                    <h3 className="font-semibold text-base sm:text-lg mb-3">
                      This course includes
                    </h3>
                    <ul className="list-inside text-sm text-gray-800 space-y-2">
                      {courseData?.benefits?.map((benefit, index) => (
                        <li key={index} className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4 text-green-500 mr-2"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                          {benefit}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="shadow-md border-black absolute max-md:-bottom-3 md:-bottom-5 lg:-bottom-8 left-1/2 transform -translate-x-1/2 w-[90%] md:w-[600px] h-auto bg-white border flex flex-wrap items-center justify-around max-md:px-2 lg:px-4 lg:py-4 max-md:py-2 md:py-0 md:h-[100px]">
                <div className="flex flex-col items-center text-primary w-1/3">
                  <FaSignal className="text-lg md:text-xl mb-1" />
                  <p className="text-xs font-medium text-gray-500">
                    Skill level
                  </p>
                  <p className="text-sm font-bold text-gray-900">
                    {courseData?.level}
                  </p>
                </div>
                <div className="flex flex-col items-center text-primary w-1/3">
                  <FaRegClock className="text-lg md:text-xl mb-1" />
                  <p className="text-xs font-medium text-gray-500">Duration</p>
                  <p className="text-sm font-bold text-gray-900">
                    {courseData?.time_period} hours
                  </p>
                </div>
                <div className="flex flex-col items-center text-primary w-1/3">
                  <RiQuestionAnswerFill className="text-lg md:text-xl mb-1" />
                  <p className="text-xs font-medium text-gray-500">
                    Total Questions
                  </p>
                  <p className="text-sm font-bold text-gray-900">
                    {courseData?.medium_count
                      ? courseData?.medium_count +
                        courseData?.easy_count +
                        courseData?.hard_count
                      : 0}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center w-full mb-10 mx-auto">
            <div className="mt-14 rounded-md flex flex-col gap-2 w-[90%] mx-auto">
              {courseData?.categories?.length === 0 ? (
                <p className="text-center text-gray-500">
                  No Categories available
                </p>
              ) : (
                courseData?.categories?.map((cat, index) => (
                  <StudentCategoryCard
                    key={cat.id}
                    name={cat.name}
                    description={cat.description}
                    cat_id={cat.id}
                    course_id={courseId}
                    clusterid={clusterid}
                    number={index + 1}
                    categoryDetails={cat}
                    isPaidCourse={isPaidCourse}
                    isPurchasedCourse={isCoursePurchased}
                    courseData={courseData}
                  />
                ))
              )}
            </div>
          </div>
        </div>
        {/* Sidebar */}
        {!isRecommendedEmpty && (
          <div className="hidden md:block w-[25%]">
            <div className="h-[70%] p-5">
              <div className="overflow-y-auto">
                <RecommendedCourseCards
                  courseid={courseId}
                  setIsRecommendedEmpty={setIsRecommendedEmpty}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Modal.Header>
          <h2 className="text-lg font-bold text-gray-900">
            Unlock Your Potential with This Course
          </h2>
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            {/* Course Details */}
            <div>
              <h3 className="font-semibold text-lg text-gray-800">
                Why Choose This Course?
              </h3>
              <p className="text-gray-700">{courseData?.whyChoose}</p>
            </div>

            <div className="border-t border-gray-200 pt-4">
              <h3 className="text-lg font-bold text-gray-800 mb-3">
                Payment Summary
              </h3>
              <div className="flex justify-between items-center mb-2">
                <span className="text-sm text-gray-500">Price:</span>
                <span className="text-sm">₹{courseData?.dis_price}</span>
              </div>
              <div className="flex justify-between items-center mb-2">
                <span className="text-sm text-gray-500">Tax (18%) :</span>
                <span className="text-sm">₹{courseData?.dis_price * 0.18}</span>
              </div>
              <div className="flex justify-between items-center mt-4">
                <span className="text-lg font-bold text-gray-800">Total:</span>
                <span className="text-lg font-bold text-gray-800">
                  ₹{(courseData?.dis_price * 1.18).toFixed(2)}
                </span>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="flex flex-col items-center space-y-2">
          <FlowbiteButton
            className="bg-primary text-white w-full font-medium rounded-md hover:bg-primary-dark transition duration-200"
            onClick={handlePayNow}
          >
            Purchase Now
          </FlowbiteButton>
          <p className="text-xs text-gray-500 mt-2 text-center">
            Start learning risk-free today!
          </p>
        </Modal.Footer>
      </Modal>
    </StudentLayout>
  );
};

export default StudentCourseDetails;
