import React, { useEffect, useState } from "react";
import { Sidebar } from "flowbite-react";
import { HiChartPie, HiViewBoards } from "react-icons/hi";
import { IoBookSharp, IoSettingsOutline } from "react-icons/io5";
import { MdAssignment } from "react-icons/md";
import { Link } from "react-router-dom";
import { useGetStudentByEmailIdQuery } from "../../../graphql/generated.tsx";

function LeftSideBar() {
  const [currentPage, setCurrentPage] = useState("");

  const email = JSON.parse(localStorage.getItem("user"))?.email;
  const { data: student, loading: studentLoading } =
    useGetStudentByEmailIdQuery({
      variables: { email },
    });

  const studentDetails = student?.getStudentByEmailId;

  useEffect(() => {
    const newPage = window.location.pathname;
    setCurrentPage(newPage);
  }, [setCurrentPage]);

  const customTheme = {
    root: {
      base: "bg-white",
      inner: "bg-white",
    },
  };

  return (
    <div className="lg:w-12 lg:flex-shrink-0 h-full">
      <Sidebar
        collapsed={true}
        theme={customTheme}
        className="flex p-4 mt-3 fixed top-0 left-0 z-20 flex-col flex-shrink-0 pt-16 h-full duration-75 border-r border-gray-200 lg:flex transition-width dark:border-gray-700 bg-primary"
      >
        <Sidebar.Items>
          <Sidebar.ItemGroup className="bg-primary text-yellow-200">
            <Sidebar.Item
              as={Link}
              to="/dashboard"
              icon={HiChartPie}
              className={`flex items-center justify-center ${
                currentPage === "/dashboard" ? "bg-gray-200" : "hover:bg-primary"
              }`}
            >
              Dashboard
            </Sidebar.Item>

            {studentDetails?.clusterIds.length > 0 && (
              <Sidebar.Item
                as={Link}
                to={
                  studentDetails?.clusterIds.length === 1
                    ? `/student/courses/${studentDetails?.clusterIds[0]}`
                    : "/student/courses"
                }
                icon={IoBookSharp}
                className={`flex items-center justify-center ${
                  currentPage.startsWith("/student/courses") ? "bg-gray-200" : "hover:bg-primary"
                }`}
              >
                Courses
              </Sidebar.Item>
            )}

            <Sidebar.Item
              as={Link}
              to="/assignments"
              icon={HiViewBoards}
              className={`flex items-center justify-center ${
                currentPage.startsWith("/assignments") ? "bg-gray-200" : "hover:bg-primary"
              }`}
            >
              Assignments
            </Sidebar.Item>

            <Sidebar.Item
              as={Link}
              to="/student/assignment-leaderboard"
              icon={MdAssignment}
              className={`flex items-center justify-center ${
                currentPage.startsWith("/student/assignment-leaderboard") ? "bg-gray-200" : "hover:bg-primary"
              }`}
            >
              Leaderboard
            </Sidebar.Item>

            <Sidebar.Item
              as={Link}
              to="/student/my-profile"
              icon={IoSettingsOutline}
              className={`flex items-center justify-center ${
                currentPage.startsWith("/student/my-profile") ? "bg-gray-200" : "hover:bg-primary"
              }`}
            >
              Settings
            </Sidebar.Item>
          </Sidebar.ItemGroup>
        </Sidebar.Items>
      </Sidebar>
    </div>
  );
}

export default LeftSideBar;
