import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import {
  useGetCampusQuery,
  useGetCategoryDashboardDetailsQuery,
  useGetCourseForStudentsQuery,
  useGetQuestionForStudentsQuery,
  useGetStudentByEmailIdQuery,
} from "../../../graphql/generated.tsx";
import StudentLayout from "../../Student/Layout/index.jsx";
import QuestionListingTable from "../Components/QuestionListingTable.jsx";
import { FaCheckToSlot, FaTableList } from "react-icons/fa6";
import { TbProgressBolt } from "react-icons/tb";
import { MdOutlineGrade } from "react-icons/md";
import { Select } from "flowbite-react";
import { debounce } from "lodash";
import { useLocation } from "react-router-dom";

const StudentQuestionListing = () => {
  const { categoryId, courseId, clusterid } = useParams();
  const [questions, setQuestions] = useState([]);
  const [submissions, setSubmissions] = useState({});
  const [completedCount, setCompletedCount] = useState(0);
  const [catName, setCatName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedQuestions, setPaginatedQuestions] = useState([]);
  const [filter, setFilter] = useState("all");
  const [difficulty, setDifficulty] = useState("all");
  const [search, setSearch] = useState("");
  const [questionQuery, setQuestionQuery] = useState("");
  const questionsPerPage = 10;
  const [loading, setLoading] = useState(true);
  const [inProgressCount, setInProgressCount] = useState(0);
  const [totalScore, setTotalScore] = useState(0);
  const [maxScore, setMaxScore] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const { data: courseDetailsWithQuestions } = useGetCourseForStudentsQuery({ variables: { id: courseId } });
  
  const location = useLocation()


  const getStatusParam = () => {
    if (filter === "all") return undefined;
    const statusMap = {
      completed: 2,
      inprogress: 1,
      notstarted: 0,
    };
    return statusMap[filter];
  };

  const getDifficultyParam = () => {
    return difficulty === "all" ? undefined : difficulty;
  };

  const getSearchParam = () => {
    return questionQuery.trim() === "" ? undefined : questionQuery;
  };

  const email = JSON.parse(localStorage.getItem("user"))?.email;
  const { data: studentData } = useGetStudentByEmailIdQuery({
    variables: { email },
  });

  const { data: campusData } = useGetCampusQuery({
    variables: { id: studentData?.getStudentByEmailId?.campusId },
    skip: !studentData,
  });

  const { data: dashboardData, loading: dashboardLoading } =
    useGetCategoryDashboardDetailsQuery({
      variables: {
        course_id: courseId,
        category_id: categoryId,
        campus_name: campusData?.campus?.name,
        student_id: studentData?.getStudentByEmailId?.id,
      },
      skip: !studentData || !campusData,
    });

  const { data: questionsData, loading: questionsLoading } =
    useGetQuestionForStudentsQuery({
      variables: {
        campus_name: campusData?.campus?.name,
        cluster_id: clusterid,
        course_id: courseId,
        category_id: categoryId,
        student_id: studentData?.getStudentByEmailId?.id,
        page: currentPage,
        limit: questionsPerPage,
        status: getStatusParam(),
        difficulty: getDifficultyParam(),
        text: getSearchParam(),
      },
      skip: !studentData || !campusData,
      fetchPolicy:"network-only"
    });

  useEffect(() => {

    const pageNumber = location?.state?.pageNumber || 1

    if (studentData && campusData) {
      setCurrentPage(pageNumber);
    }
  }, [filter, difficulty, search]);

  useEffect(() => {
    if (dashboardData?.getCategoryDashboardDetails) {
      const details = dashboardData.getCategoryDashboardDetails;
      const inProgressCount = details.inProgressCount || 0;
      const completedCount = details.completedCount || 0;
      const awaitingCount = details.awaitingCount || 0;
      setTotalScore(details.totalScore || 0);
      setMaxScore(details.maxScore || 0);
      setInProgressCount(inProgressCount);
      setCompletedCount(completedCount);
      setCatName(details.categoryName || "");
      setTotalQuestions(inProgressCount + completedCount + awaitingCount);
    }
  }, [dashboardData]);

  useEffect(() => {
    if (questionsData?.getQuestionForStudents) {
      const questionList = questionsData.getQuestionForStudents.questions || [];
      const totalCount = questionsData.getQuestionForStudents.totalCount || 0;

      setTotalPages(Math.ceil(totalCount / questionsPerPage));

      const submissionsMap = {};
      questionList.forEach((q) => {
        submissionsMap[q.question_id] = {
          status: q.status,
          mark: q.mark,
        };
      });

      setQuestions(questionList);
      setPaginatedQuestions(questionList);
      setSubmissions(submissionsMap);
    }
  }, [questionsData]);

  useEffect(() => {
    setLoading(
      dashboardLoading || questionsLoading || !studentData || !campusData
    );
  }, [dashboardLoading, questionsLoading, studentData, campusData]);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  const debouncedSetQuestionQuery = useMemo(() =>
    debounce((value) => {
      setQuestionQuery(value);
    }, 500)
  , []);

  useEffect(() => {
    return () => {
      debouncedSetQuestionQuery.cancel();
    };
  }, [debouncedSetQuestionQuery]);

  const handleSearch = (value) => {
    setSearch(value);
    debouncedSetQuestionQuery(value);
  };

  if (loading) {
    return (
      <StudentLayout>
        <div className="flex items-center justify-center h-screen">
          <p>Loading...</p>
        </div>
      </StudentLayout>
    );
  }

  return (
    <StudentLayout>
      <div className="p-10 max-md:p-5 max-md:ml-10">
        <div className="flex mb-10">
          <button
            onClick={() => window.history.back()}
            className="text-gray-500 hover:text-gray-700 focus:outline-none mr-4"
          >
            <IoMdArrowRoundBack className="h-6 w-6" />
          </button>
          <h2 className="text-3xl font-bold w-full max-md:text-2xl">
            {catName}
          </h2>
        </div>

        <div className="flex flex-wrap gap-4 my-10">
          <div className="bg-white rounded-3xl border p-6 w-60 text-center">
            <div className="mb-4 flex justify-center items-center w-full">
              <FaTableList className="h-8 w-8" />
            </div>
            <h2 className="text-xl font-bold text-gray-800">
              {totalQuestions}
            </h2>
            <p className="text-gray-500">
              Total
              <br /> Questions
            </p>
          </div>

          <div className="bg-white rounded-3xl border p-6 w-60 text-center">
            <div className="mb-4 flex justify-center items-center w-full">
              <FaCheckToSlot className="h-8 w-8" />
            </div>
            <h2 className="text-xl font-bold text-gray-800">
              {completedCount}
            </h2>
            <p className="text-gray-500">
              Completed <br />
              Questions
            </p>
          </div>

          <div className="bg-white rounded-3xl border p-5 w-60 text-center">
            <div className="mb-4 flex justify-center items-center w-full">
              <TbProgressBolt className="h-8 w-8" />
            </div>
            <h2 className="text-xl font-bold text-gray-800">
              {inProgressCount}
            </h2>
            <p className="text-gray-500">
              In Progress
              <br /> Questions
            </p>
          </div>
          <div className="bg-white rounded-3xl border p-6 w-60 text-center">
            <div className="mb-4 flex justify-center items-center w-full">
              <MdOutlineGrade className="h-8 w-8" />
            </div>
            <h2 className="text-2xl font-bold text-gray-800">
              {totalScore}/{maxScore}
            </h2>
            <p className="text-gray-500">Total Score</p>
          </div>
        </div>

        {/* Filters */}
        <div className="flex gap-4 mb-6 max-md:flex-col">
          {/* <Select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="w-[150px]"
          >
            <option value="all">All Status</option>
            <option value="completed">Completed</option>
            <option value="inprogress">In Progress</option>
            <option value="notstarted">Awaiting</option>
          </Select> */}

          <Select
            value={difficulty}
            onChange={(e) => setDifficulty(e.target.value)}
            className="w-[150px]"
          >
            <option value="all">All Difficulty</option>
            <option value="EASY">Easy</option>
            <option value="MEDIUM">Medium</option>
            <option value="HARD">Hard</option>
          </Select>

          <input
            type="text"
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Search questions"
            className="border border-gray-300 rounded-lg px-4 py-2 w-[250px]"
          />
        </div>

        <QuestionListingTable
          data={paginatedQuestions}
          price={courseDetailsWithQuestions?.getCourseForStudents?.dis_price}
          courseId={courseId}
          clusterid={clusterid}
          submissions={submissions}
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
          currentPage={currentPage}
          categories={courseDetailsWithQuestions?.getCourseForStudents?.categories}
          whyChoose={courseDetailsWithQuestions?.getCourseForStudents?.whyChoose}
          dis_price={courseDetailsWithQuestions?.getCourseForStudents?.dis_price}
          totalPages={totalPages}
        />
      </div>
    </StudentLayout>
  );
};

export default StudentQuestionListing;