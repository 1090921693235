export const calculateCourseStats = (courses) => {
  let completed = 0,
    inProgress = 0,
    enrolled = 0;
  courses?.forEach(({ totalQuestion, completedQuestion }) => {
    enrolled++;
    if (completedQuestion === totalQuestion && totalQuestion !== 0) {
      completed++;
    } else if (completedQuestion > 0) {
      inProgress++;
    }
  });
  return { completed, inProgress, enrolled };
};
